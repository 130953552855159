/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface IAppIdentityUser {
  id?: string | null;
  userName?: string | null;
  normalizedUserName?: string | null;
  email?: string | null;
  normalizedEmail?: string | null;
  emailConfirmed?: boolean;
  passwordHash?: string | null;
  securityStamp?: string | null;
  concurrencyStamp?: string | null;
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  twoFactorEnabled?: boolean;
  /** @format date-time */
  lockoutEnd?: string | Date | null;
  lockoutEnabled?: boolean;
  /** @format int32 */
  accessFailedCount?: number;
  refreshToken?: string | null;
  /** @format date-time */
  refreshTokenExpiryDate?: string | Date | null;
  userProfileFamily?: IUserFamilyProfile;
  userProfileCrm?: IUserCrmProfile;
  userProfileTenant?: IUserTenantProfile;
  botUserProfile?: IBotUserProfile;
  productsTailorFitUserProfile?: IProductsTailorFitUserProfile;
}

export interface IBodyPart {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  bodyPartContents?: IBodyPartContent[] | null;
  caseBodyParts?: ICaseBodyPart[] | null;
}

export interface IBodyPartContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  bodyPartID?: string | null;
  bodyPart?: IBodyPart;
}

export interface IBodyPartContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBodyPartContent[];
}

export interface IBodyPartDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBodyPart[];
}

export interface IBotCase {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  caseID?: string | null;
  botUserProfile?: IBotUserProfile;
  case?: ICase;
  botCaseBrands?: IBotCaseBrand[] | null;
  botCaseTenantProducts?: IBotCaseTenantProduct[] | null;
  botSessionTenantProductActions?: IBotSessionTenantProductAction[] | null;
}

export interface IBotCaseBrand {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  botCaseID?: string | null;
  productBrandID?: string | null;
  /** @format int32 */
  maxPromotadProducts?: number;
  /** @format date-time */
  expiredDate?: string | Date;
  /** @format double */
  defaultPriceForView?: number;
  /** @format double */
  defaultPriceForClick?: number;
  /** @format double */
  defaultPriceForCart?: number;
  botCase?: IBotCase;
}

export interface IBotCaseDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotCase[];
}

export interface IBotCaseTenantProduct {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  pharmaCompanyOrderID?: string | null;
  botCaseID?: string | null;
  tenantProductID?: string | null;
  /** @format double */
  priceForView?: number;
  /** @format double */
  priceForClick?: number;
  /** @format double */
  priceForCart?: number;
  /** @format int32 */
  positionIndex?: number;
  botCase?: IBotCase;
  tenantProduct?: ITenantProduct;
  pharmaCompanyOrder?: IPharmaCompanyOrder;
}

export interface IBotCaseTenantProductDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotCaseTenantProduct[];
}

export interface IBotDomain {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  appIdentityUserID?: string | null;
  botUserProfile?: IBotUserProfile;
}

export interface IBotDomainDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotDomain[];
}

export interface IBotSession {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  userID?: string | null;
  sessionID?: string | null;
  tenantID?: string | null;
  botUserProfileID?: string | null;
  familyUserProfileID?: string | null;
  genderID?: string | null;
  languageID?: string | null;
  familyMemberID?: string | null;
  caseID?: string | null;
  /** @format double */
  age?: number | null;
  /** @format date-time */
  dateOfBirth?: string | Date | null;
  eventType?: string | null;
  url?: string | null;
  location?: string | null;
  longitude?: string | null;
  latitude?: string | null;
  deviceClientType?: string | null;
  deviceClientName?: string | null;
  deviceClientVersion?: string | null;
  deviceClientEngine?: string | null;
  deviceClientEngineVersion?: string | null;
  deviceOSName?: string | null;
  deviceOSVersion?: string | null;
  deviceOSPlatform?: string | null;
  deviceType?: string | null;
  deviceBrand?: string | null;
  deviceModel?: string | null;
  flowType?: string | null;
  flowTypeDrugCurrentStep?: string | null;
  flowTypeDrugSearchByProductID?: string | null;
  flowTypeDrugSearchByProductMedicationID?: string | null;
  flowTypeDrugDisplayAllCaseProducts?: boolean | null;
  currentBotSessionStepID?: string | null;
  lastStep?: string | null;
  referStopID?: string | null;
  referMedicalConditionID?: string | null;
  referRedFlagID?: string | null;
  referRedFlagPageID?: string | null;
  referRedFlagAnswer?: string | null;
  tenant?: ITenant;
  case?: ICase;
  gender?: IGender;
  language?: ILanguage;
  userFamilyProfile?: IUserFamilyProfile;
  familyMember?: IFamilyMember;
  botUserProfile?: IBotUserProfile;
  botSessionStep?: IBotSessionStep;
  botSessionLogs?: IBotSessionLog[] | null;
  botSessionDosageForms?: IBotSessionDosageForm[] | null;
  botSessionMedicalConditions?: IBotSessionMedicalCondition[] | null;
  botSessionSensitivities?: IBotSessionSensitivity[] | null;
  botSessionProducts?: IBotSessionProduct[] | null;
  botSessionTenantProductActions?: IBotSessionTenantProductAction[] | null;
  botSessionDietaryPreferences?: IBotSessionDietaryPreference[] | null;
}

export interface IBotSessionDietaryPreference {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  botSessionID?: string | null;
  dietaryPreferenceID?: string | null;
  chosen?: boolean;
  dietaryPreference?: IDietaryPreference;
  botSession?: IBotSession;
}

export interface IBotSessionDosageForm {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  botSessionID?: string | null;
  dosageFormID?: string | null;
  chosen?: boolean;
  dosageForm?: IDosageForm;
  botSession?: IBotSession;
}

export interface IBotSessionDosageFormDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotSessionDosageForm[];
}

export interface IBotSessionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotSession[];
}

export interface IBotSessionLog {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  botSessionID?: string | null;
  botSessionLogSource?: IBotSessionLogSource;
  sessionID?: string | null;
  /** @format date-time */
  eventDate?: string | Date;
  /** @format int32 */
  orderIndex?: number;
  flowType?: string | null;
  caseID?: string | null;
  casePageID?: string | null;
  userActionType?: string | null;
  userActionKey?: string | null;
  userActionValue?: string | null;
  userActionEntityID?: string | null;
  botSession?: IBotSession;
}

export interface IBotSessionLogDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotSessionLog[];
}

export enum IBotSessionLogSource {
  Bot = 'Bot',
  Portal = 'Portal',
  Platform = 'Platform',
}

export interface IBotSessionMedicalCondition {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  botSessionID?: string | null;
  medicalConditionID?: string | null;
  medicalCondition?: IMedicalCondition;
  botSession?: IBotSession;
}

export interface IBotSessionMedicalConditionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotSessionMedicalCondition[];
}

export interface IBotSessionProduct {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  botSessionID?: string | null;
  tenantProductID?: string | null;
  /** @format int32 */
  score?: number | null;
  isFavorite?: boolean;
  /** @format int32 */
  positionIndex?: number;
  isPromoted?: boolean;
  botSession?: IBotSession;
  tenantProduct?: ITenantProduct;
}

export interface IBotSessionProductDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotSessionProduct[];
}

export interface IBotSessionSensitivity {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  botSessionID?: string | null;
  sensitivityID?: string | null;
  sensitivity?: ISensitivity;
  botSession?: IBotSession;
}

export interface IBotSessionSensitivityDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotSessionSensitivity[];
}

export interface IBotSessionStep {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  sessionStep?: ISessionStep;
  /** @format int32 */
  flowCompletePercentage?: number;
  isSessionComplete?: boolean;
  botSessions?: IBotSession[] | null;
}

export interface IBotSessionStepDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotSessionStep[];
}

export interface IBotSessionTenantProductAction {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  pharmaCompanyOrderID?: string | null;
  botSessionID?: string | null;
  botCaseID?: string | null;
  tenantProductID?: string | null;
  /** @format date-time */
  entryDate?: string | Date;
  actionType?: string | null;
  /** @format double */
  priceForAction?: number;
  /** @format int32 */
  positionIndex?: number;
  botSession?: IBotSession;
  botCase?: IBotCase;
  tenantProduct?: ITenantProduct;
  pharmaCompanyOrder?: IPharmaCompanyOrder;
}

export interface IBotSessionTenantProductActionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotSessionTenantProductAction[];
}

export interface IBotTheme {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  description?: string | null;
  textPrimary?: string | null;
  textSecondary?: string | null;
  warningColor?: string | null;
  warningContrastColor?: string | null;
  errorColor?: string | null;
  errorContrastColor?: string | null;
  primaryColor?: string | null;
  primaryContrastColor?: string | null;
  primaryGradientColor?: string | null;
  secondaryColor?: string | null;
  secondaryContrastColor?: string | null;
  secondaryGradientColor?: string | null;
  bgPrimary?: string | null;
  bgSecondary?: string | null;
  botUserProfiles?: IBotUserProfile[] | null;
}

export interface IBotThemeDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotTheme[];
}

export interface IBotUserProfile {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  appIdentityUserID?: string | null;
  languageID?: string | null;
  tenantID?: string | null;
  genderID?: string | null;
  botThemeID?: string | null;
  /** @format double */
  fromAge?: number;
  /** @format double */
  toAge?: number;
  propertyCode?: string | null;
  secret?: string | null;
  icon?: string | null;
  iconPosition?: IIconPosition;
  iconPositionVerticalMargin?: string | null;
  iconPositionHorizontalMargin?: string | null;
  currency?: string | null;
  chooseGenderTitleLabel?: string | null;
  chooseGenderSubTitleLabel?: string | null;
  chooseScenarioLabel?: string | null;
  filterMedicalConditionsTitleLabel?: string | null;
  filterMedicalConditionsSubTitleLabel?: string | null;
  filterDietaryPreferencesTitleLabel?: string | null;
  filterDietaryPreferencesSubTitleLabel?: string | null;
  filterDosageFormsTitleLabel?: string | null;
  filterDosageFormsSubTitleLabel?: string | null;
  /** @format int32 */
  botZIndex?: number;
  applyAddToCart?: boolean;
  addToCartCode?: string | null;
  onboardUserInBot?: boolean;
  displayPortalBanner?: boolean;
  displayCaseCategories?: boolean;
  displayAge?: boolean;
  promotedProductBrandID?: string | null;
  trackLog?: boolean;
  internal?: boolean;
  universal?: boolean;
  useForVitAssist?: boolean;
  useForPharmAssist?: boolean;
  displayGenderOther?: boolean;
  displayRedFlagConfirm?: boolean;
  displayRecommendationBasket?: boolean;
  displaySearch?: boolean;
  displayLanguageSelector?: boolean;
  displayUnitPrice?: boolean;
  displayProductsInfoHeader?: boolean;
  displayProductMoreInfo?: boolean;
  displayExternalVendor?: boolean;
  displayProductZooming?: boolean;
  displayFilterByActiveIngredients?: boolean;
  displayFilterByDosageForms?: boolean;
  displaySalesValidDate?: boolean;
  cssInjection?: string | null;
  brandName?: string | null;
  appIdentityUser?: IAppIdentityUser;
  gender?: IGender;
  tenant?: ITenant;
  language?: ILanguage;
  botTheme?: IBotTheme;
  promotedProductBrand?: IProductBrand;
  botDomains?: IBotDomain[] | null;
  botCases?: IBotCase[] | null;
  botWelcomeMessages?: IBotWelcomeMessage[] | null;
  botSessions?: IBotSession[] | null;
}

export interface IBotUserProfileDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotUserProfile[];
}

export interface IBotWelcomeMessage {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  languageID?: string | null;
  message?: string | null;
  /** @format int32 */
  dialogMaxWidth?: number;
  welcomeTitle?: string | null;
  welcomeSubTitle?: string | null;
  welcomeMessage?: string | null;
  welcomeBanner?: string | null;
  logo?: string | null;
  disclaimer?: string | null;
  termsAndConditions?: string | null;
  privacyPolicy?: string | null;
  termsAndConditionsHtmlContent?: string | null;
  privacyPolicyHtmlContent?: string | null;
  termsAndPrivacyLabel?: string | null;
  helpMeLabel?: string | null;
  productSubTitleLabel?: string | null;
  language?: ILanguage;
  botUserProfile?: IBotUserProfile;
}

export interface IBotWelcomeMessageDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBotWelcomeMessage[];
}

export enum ICPType {
  RedFlag = 'RedFlag',
  DosageForm = 'DosageForm',
  Symptom = 'Symptom',
  Sensitivity = 'Sensitivity',
  Message = 'Message',
  MedicalCondition = 'MedicalCondition',
  Decision = 'Decision',
  Start = 'Start',
  Stop = 'Stop',
  End = 'End',
  ComputeMedicalCondition = 'ComputeMedicalCondition',
  MedicalConditionGroup = 'MedicalConditionGroup',
  DietaryPreference = 'DietaryPreference',
}

export interface ICase {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  caseCategoryID?: string | null;
  genderID?: string | null;
  countryID?: string | null;
  /** @format double */
  fromAge?: number;
  /** @format double */
  toAge?: number;
  catalogName?: string | null;
  versionHash?: string | null;
  useForVitAssist?: boolean;
  useForPharmAssist?: boolean;
  country?: ICountry;
  gender?: IGender;
  caseCategory?: ICaseCategory;
  caseContents?: ICaseContent[] | null;
  casePages?: ICasePage[] | null;
  caseTags?: ICaseTag[] | null;
  caseBodyParts?: ICaseBodyPart[] | null;
  caseHealthCareTips?: ICaseHealthCareTip[] | null;
  caseMedications?: ICaseMedication[] | null;
  productCases?: IProductCase[] | null;
  botCases?: IBotCase[] | null;
  productsTailorFitCases?: IProductsTailorFitCase[] | null;
  botSessions?: IBotSession[] | null;
  productsTailorFitSessions?: IProductsTailorFitSession[] | null;
  redisParams?: IRedisParam[] | null;
}

export interface ICaseBodyPart {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  caseID?: string | null;
  bodyPartID?: string | null;
  case?: ICase;
  bodyPart?: IBodyPart;
}

export interface ICaseBodyPartDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseBodyPart[];
}

export interface ICaseCategory {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  caseCategoryContents?: ICaseCategoryContent[] | null;
  cases?: ICase[] | null;
}

export interface ICaseCategoryContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  caseCategoryID?: string | null;
  caseCategory?: ICaseCategory;
}

export interface ICaseCategoryContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseCategoryContent[];
}

export interface ICaseCategoryDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseCategory[];
}

export interface ICaseComputeMedicalCondition {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  displayPrintIcon?: boolean;
  casePage?: ICasePage;
}

export interface ICaseComputeMedicalConditionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseComputeMedicalCondition[];
}

export interface ICaseContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  caseID?: string | null;
  tips?: string | null;
  info?: string | null;
  case?: ICase;
}

export interface ICaseContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseContent[];
}

export interface ICaseDecision {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  /** @format int32 */
  nextPageNumber?: number;
  catalogName?: string | null;
  nextCasePageID?: string | null;
  /** @format int32 */
  fromArrowPosition?: number | null;
  /** @format int32 */
  toArrowPosition?: number | null;
  casePage?: ICasePage;
  caseDecisionContents?: ICaseDecisionContent[] | null;
}

export interface ICaseDecisionContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  caseDecisionID?: string | null;
  caseDecision?: ICaseDecision;
}

export interface ICaseDecisionContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseDecisionContent[];
}

export interface ICaseDecisionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseDecision[];
}

export interface ICaseDietaryPreference {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  dietaryPreferenceID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  casePage?: ICasePage;
  dietaryPreference?: IDietaryPreference;
}

export interface ICaseDosageForm {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  dosageFormID?: string | null;
  casePage?: ICasePage;
  dosageForm?: IDosageForm;
}

export interface ICaseDosageFormDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseDosageForm[];
}

export interface ICaseDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICase[];
}

export interface ICaseHealthCareTip {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  caseID?: string | null;
  healthCareTipID?: string | null;
  case?: ICase;
  healthCareTip?: IHealthCareTip;
}

export interface ICaseMedicalCondition {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  medicalConditionID?: string | null;
  caseMedicalConditionGroupID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  referOnYes?: boolean | null;
  referOnNo?: boolean | null;
  casePage?: ICasePage;
  medicalCondition?: IMedicalCondition;
  caseMedicalConditionGroup?: ICaseMedicalConditionGroup;
}

export interface ICaseMedicalConditionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseMedicalCondition[];
}

export interface ICaseMedicalConditionGroup {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  /** @format int32 */
  pageNumber?: number;
  catalogName?: string | null;
  casePage?: ICasePage;
  caseMedicalConditionGroupContents?: ICaseMedicalConditionGroupContent[] | null;
  caseMedicalConditions?: ICaseMedicalCondition[] | null;
}

export interface ICaseMedicalConditionGroupContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  caseMedicalConditionGroupID?: string | null;
  caseMedicalConditionGroup?: ICaseMedicalConditionGroup;
}

export interface ICaseMedicalConditionGroupContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseMedicalConditionGroupContent[];
}

export interface ICaseMedicalConditionGroupDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseMedicalConditionGroup[];
}

export interface ICaseMedication {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  caseID?: string | null;
  medicationID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  referOnYes?: boolean | null;
  referOnNo?: boolean | null;
  case?: ICase;
  medication?: IMedication;
}

export interface ICaseMedicationDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseMedication[];
}

export interface ICasePage {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  caseID?: string | null;
  genderID?: string | null;
  /** @format double */
  fromAge?: number;
  /** @format double */
  toAge?: number;
  casePageTypeID?: string | null;
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  nextPageNumber?: number | null;
  catalogName?: string | null;
  /** @format int32 */
  delay?: number;
  autoSkip?: boolean;
  required?: boolean;
  /** @format double */
  x?: number;
  /** @format double */
  y?: number;
  nextCasePageID?: string | null;
  /** @format int32 */
  fromArrowPosition?: number | null;
  /** @format int32 */
  toArrowPosition?: number | null;
  case?: ICase;
  casePageType?: ICasePageType;
  gender?: IGender;
  redFlagPages?: IRedFlagPage[] | null;
  dosageForms?: IDosageForm[] | null;
  caseDecisions?: ICaseDecision[] | null;
  caseSensitivities?: ICaseSensitivity[] | null;
  caseSymptoms?: ICaseSymptom[] | null;
  caseDosageForms?: ICaseDosageForm[] | null;
  caseDietaryPreferences?: ICaseDietaryPreference[] | null;
  caseMedicalConditions?: ICaseMedicalCondition[] | null;
  caseComputeMedicalConditions?: ICaseComputeMedicalCondition[] | null;
  caseMedicalConditionGroups?: ICaseMedicalConditionGroup[] | null;
  casePageContents?: ICasePageContent[] | null;
  productsTailorFitCases?: IProductsTailorFitCase[] | null;
}

export interface ICasePageContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  casePageID?: string | null;
  casePage?: ICasePage;
}

export interface ICasePageContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICasePageContent[];
}

export interface ICasePageDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICasePage[];
}

export interface ICasePageType {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  cpType?: ICPType;
  casePages?: ICasePage[] | null;
}

export interface ICasePageTypeDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICasePageType[];
}

export interface ICaseSensitivity {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  sensitivityID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  referOnYes?: boolean | null;
  referOnNo?: boolean | null;
  casePage?: ICasePage;
  sensitivity?: ISensitivity;
}

export interface ICaseSensitivityDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseSensitivity[];
}

export interface ICaseSymptom {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  symptomID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  referOnYes?: boolean | null;
  referOnNo?: boolean | null;
  casePage?: ICasePage;
  symptom?: ISymptom;
}

export interface ICaseTag {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  caseID?: string | null;
  case?: ICase;
}

export interface ICaseTagDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICaseTag[];
}

export interface ICloudFileViewModel {
  fileName?: string | null;
  fileStreamString?: string | null;
  filePath?: string | null;
  isImage?: boolean;
}

export interface ICommonPage {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  languageID?: string | null;
  commonPageType?: IPageType;
  description?: string | null;
  htmlContent?: string | null;
  html?: string | null;
  picture?: string | null;
  canonical?: string | null;
  metaTitle?: string | null;
  metaDescription?: string | null;
  metaKeyWords?: string | null;
  language?: ILanguage;
  commonPageSections?: ICommonPageSection[] | null;
}

export interface ICommonPageSection {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  commonPageID?: string | null;
  sectionType?: ISectionType;
  htmlContent?: string | null;
  picture?: string | null;
  video?: string | null;
  commonPage?: ICommonPage;
  commonPageSectionItems?: ICommonPageSectionItem[] | null;
}

export interface ICommonPageSectionItem {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  commonPageSectionID?: string | null;
  description?: string | null;
  htmlContent?: string | null;
  picture?: string | null;
  video?: string | null;
  pictureLarge?: string | null;
  cssClass?: string | null;
  /** @format int32 */
  rank?: number;
  isSecondary?: boolean;
  commonPageSection?: ICommonPageSection;
}

export interface ICountry {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  countryName?: string | null;
  flag?: string | null;
  countryCode?: string | null;
  internationalDailingCode?: string | null;
  highlight?: boolean;
  pharmaCompanies?: IPharmaCompany[] | null;
  pharmaCompanyOrders?: IPharmaCompanyOrder[] | null;
  products?: IProduct[] | null;
  tenants?: ITenant[] | null;
  cases?: ICase[] | null;
  countryLanguages?: ICountryLanguage[] | null;
}

export interface ICountryDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICountry[];
}

export interface ICountryLanguage {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  countryID?: string | null;
  languageID?: string | null;
  country?: ICountry;
  language?: ILanguage;
}

export interface ICountryLanguageDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ICountryLanguage[];
}

export interface ICurrentUserProfileResponse {
  appIdentityUserID?: string | null;
  userName?: string | null;
  userProfileTenants?: IUserProfileTenant[] | null;
  isActive?: boolean;
  isAdmin?: boolean;
}

export interface IDietaryPreference {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  useForVitAssist?: boolean;
  useForPharmAssist?: boolean;
  dietaryPreferenceContents?: IDietaryPreferenceContent[] | null;
  caseDietaryPreferences?: ICaseDietaryPreference[] | null;
  productDietaryPreferences?: IProductDietaryPreference[] | null;
  botSessionDietaryPreferences?: IBotSessionDietaryPreference[] | null;
}

export interface IDietaryPreferenceContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  dietaryPreferenceID?: string | null;
  dietaryPreference?: IDietaryPreference;
}

export interface IDosageForm {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  dosageFormCategoryID?: string | null;
  dosageFormUnitTypeID?: string | null;
  catalogName?: string | null;
  dosageFormCategory?: IDosageFormCategory;
  dosageFormUnitType?: IDosageFormUnitType;
  dosageFormContents?: IDosageFormContent[] | null;
  caseDosageForms?: ICaseDosageForm[] | null;
  products?: IProduct[] | null;
  botSessionDosageForms?: IBotSessionDosageForm[] | null;
  productsTailorFitSessionDosageForms?: IProductsTailorFitSessionDosageForm[] | null;
}

export interface IDosageFormCategory {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  dosageForms?: IDosageForm[] | null;
  dosageFormCategoryContents?: IDosageFormCategoryContent[] | null;
}

export interface IDosageFormCategoryContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  dosageFormCategoryID?: string | null;
  dosageFormCategory?: IDosageFormCategory;
}

export interface IDosageFormCategoryContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDosageFormCategoryContent[];
}

export interface IDosageFormCategoryDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDosageFormCategory[];
}

export interface IDosageFormContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  dosageFormID?: string | null;
  dosageForm?: IDosageForm;
}

export interface IDosageFormContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDosageFormContent[];
}

export interface IDosageFormDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDosageForm[];
}

export interface IDosageFormUnitType {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  dosageFormUnitTypeContents?: IDosageFormUnitTypeContent[] | null;
  dosageForms?: IDosageForm[] | null;
}

export interface IDosageFormUnitTypeContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  dosageFormUnitTypeID?: string | null;
  dosageFormUnitType?: IDosageFormUnitType;
}

export interface IDosageFormUnitTypeContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDosageFormUnitTypeContent[];
}

export interface IDosageFormUnitTypeDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDosageFormUnitType[];
}

export interface IFamilyMember {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  genderID?: string | null;
  /** @format date-time */
  dateOfBirth?: string | Date | null;
  fullName?: string | null;
  userPhoto?: string | null;
  userFamilyProfile?: IUserFamilyProfile;
  gender?: IGender;
  botSessions?: IBotSession[] | null;
}

export interface IGender {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  isBothOrNotRelevant?: boolean;
  genderContents?: IGenderContent[] | null;
  products?: IProduct[] | null;
  cases?: ICase[] | null;
  medicalConditions?: IMedicalCondition[] | null;
  symptoms?: ISymptom[] | null;
  redFlags?: IRedFlag[] | null;
  userFamilyProfiles?: IUserFamilyProfile[] | null;
  familyMembers?: IFamilyMember[] | null;
  botSessions?: IBotSession[] | null;
  productsTailorFitSessions?: IProductsTailorFitSession[] | null;
  languages?: ILanguage[] | null;
  botUserProfiles?: IBotUserProfile[] | null;
  productsTailorFitUserProfiles?: IProductsTailorFitUserProfile[] | null;
  casePages?: ICasePage[] | null;
}

export interface IGenderContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  genderID?: string | null;
  gender?: IGender;
}

export interface IGenderContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IGenderContent[];
}

export interface IGenderDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IGender[];
}

export interface IGeneratePasswordRequest {
  email?: string | null;
  languageID?: string | null;
}

export interface IHealthCareTip {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  healthCareTipContents?: IHealthCareTipContent[] | null;
  caseHealthCareTips?: ICaseHealthCareTip[] | null;
}

export interface IHealthCareTipContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  healthCareTipID?: string | null;
  healthCareTip?: IHealthCareTip;
}

export enum IIconPosition {
  TopLeft = 'TopLeft',
  TopRight = 'TopRight',
  MidLeft = 'MidLeft',
  MidRight = 'MidRight',
  BottomLeft = 'BottomLeft',
  BottomRight = 'BottomRight',
}

export interface IJwt {
  token?: string | null;
  refreshToken?: string | null;
}

export interface ILabel {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  languageID?: string | null;
  labelSourceID?: string | null;
  labelKey?: string | null;
  labelSource?: ILabelSource;
  language?: ILanguage;
}

export interface ILabelDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ILabel[];
}

export interface ILabelSource {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  key?: string | null;
  labels?: ILabel[] | null;
}

export interface ILandingPageResponse {
  languageID?: string | null;
  searchEngineOptimization?: ISearchEngineOptimization;
  copyright?: string | null;
  slogan?: string | null;
  socialMediaItems?: ISocialMedia[] | null;
  htmlContent?: string | null;
}

export interface ILanguage {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  direction?: ILanguageDirection;
  genderID?: string | null;
  culture?: string | null;
  icon?: string | null;
  currency?: string | null;
  importName?: string | null;
  isoCode?: string | null;
  /** @format int32 */
  rank?: number;
  isMajor?: boolean;
  isRTL?: boolean;
  gender?: IGender;
  labels?: ILabel[] | null;
  commonPages?: ICommonPage[] | null;
  userProfileFamilies?: IUserFamilyProfile[] | null;
  userProfileCRMs?: IUserCrmProfile[] | null;
  siteParameters?: ISiteParameter[] | null;
  botWelcomeMessages?: IBotWelcomeMessage[] | null;
  botUserProfiles?: IBotUserProfile[] | null;
  productsTailorFitUserProfiles?: IProductsTailorFitUserProfile[] | null;
  productsTailorFitUserProfileContents?: IProductsTailorFitUserProfileContent[] | null;
  botSessions?: IBotSession[] | null;
  productsTailorFitSessions?: IProductsTailorFitSession[] | null;
  tenantBotContents?: ITenantBotContent[] | null;
  countryLanguages?: ICountryLanguage[] | null;
  redisParams?: IRedisParam[] | null;
}

export enum ILanguageDirection {
  LTR = 'LTR',
  RTL = 'RTL',
}

export interface ILanguageDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ILanguage[];
}

export interface ILegalResponse {
  languageID?: string | null;
  searchEngineOptimization?: ISearchEngineOptimization;
  copyright?: string | null;
  slogan?: string | null;
  socialMediaItems?: ISocialMedia[] | null;
  title?: string | null;
  htmlContent?: string | null;
}

export interface ILoginRequest {
  email?: string | null;
  code?: string | null;
  languageID?: string | null;
}

export interface ILoginResponse {
  jwt?: IJwt;
  /** @format date-time */
  expires?: string | Date;
}

export interface IMedicalCondition {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  genderID?: string | null;
  /** @format double */
  fromAge?: number;
  /** @format double */
  toAge?: number;
  useForVitAssist?: boolean;
  useForPharmAssist?: boolean;
  gender?: IGender;
  medicalConditionContents?: IMedicalConditionContent[] | null;
  caseMedicalConditions?: ICaseMedicalCondition[] | null;
  productMedicalConditions?: IProductMedicalCondition[] | null;
  botSessionMedicalConditions?: IBotSessionMedicalCondition[] | null;
  medicalConditionIngredients?: IMedicalConditionIngredient[] | null;
}

export interface IMedicalConditionContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  medicalConditionID?: string | null;
  medicalCondition?: IMedicalCondition;
}

export interface IMedicalConditionContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMedicalConditionContent[];
}

export interface IMedicalConditionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMedicalCondition[];
}

export interface IMedicalConditionIngredient {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  medicalConditionID?: string | null;
  ingredientID?: string | null;
  catalogName?: string | null;
  warning?: boolean;
  displayInstructions?: boolean;
  medicalCondition?: IMedicalCondition;
  ingredient?: ISensitivity;
  medicalConditionIngredientContents?: IMedicalConditionIngredientContent[] | null;
}

export interface IMedicalConditionIngredientContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  medicalConditionIngredientID?: string | null;
  medicalConditionIngredient?: IMedicalConditionIngredient;
}

export interface IMedicalConditionIngredientContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMedicalConditionIngredientContent[];
}

export interface IMedicalConditionIngredientDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMedicalConditionIngredient[];
}

export interface IMedication {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  medicationContents?: IMedicationContent[] | null;
  caseMedications?: ICaseMedication[] | null;
  products?: IProduct[] | null;
  productMedications?: IProductMedication[] | null;
}

export interface IMedicationContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  medicationID?: string | null;
  medication?: IMedication;
}

export interface IMedicationContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMedicationContent[];
}

export interface IMedicationDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMedication[];
}

export interface IOperation {
  operationType?: IOperationType;
  path?: string | null;
  op?: string | null;
  from?: string | null;
  value?: any;
}

export enum IOperationType {
  Add = 'Add',
  Remove = 'Remove',
  Replace = 'Replace',
  Move = 'Move',
  Copy = 'Copy',
  Test = 'Test',
  Invalid = 'Invalid',
}

export interface IOrderStatus {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  orderStatusType?: IOrderStatusType;
  pharmaCompanyOrders?: IPharmaCompanyOrder[] | null;
}

export interface IOrderStatusDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IOrderStatus[];
}

export enum IOrderStatusType {
  Active = 'Active',
  Pending = 'Pending',
  Trial = 'Trial',
  Archive = 'Archive',
}

export enum IPageType {
  Promo = 'Promo',
  Platform = 'Platform',
  Portal = 'Portal',
  Privacy = 'Privacy',
  Terms = 'Terms',
  WhoAreWe = 'WhoAreWe',
  WhatDoWeOffer = 'WhatDoWeOffer',
  WhyPharmAssist = 'WhyPharmAssist',
  TreatYourself = 'TreatYourself',
  Faq = 'Faq',
  CompanyOverview = 'CompanyOverview',
  Team = 'Team',
  Traction = 'Traction',
  Contact = 'Contact',
  Thanks = 'Thanks',
}

export interface IPharmaCompany {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  countryID?: string | null;
  companyName?: string | null;
  country?: ICountry;
  pharmaCompanyOrders?: IPharmaCompanyOrder[] | null;
  products?: IProduct[] | null;
}

export interface IPharmaCompanyDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IPharmaCompany[];
}

export interface IPharmaCompanyOrder {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  pharmaCompanyID?: string | null;
  orderStatusID?: string | null;
  countryID?: string | null;
  orderNumber?: string | null;
  invoiceNumber?: string | null;
  /** @format double */
  pricePerDay?: number | null;
  /** @format double */
  totalPrice?: number | null;
  /** @format int32 */
  maxProducts?: number | null;
  /** @format date-time */
  startDate?: string | Date;
  /** @format date-time */
  endDate?: string | Date;
  pharmaCompany?: IPharmaCompany;
  orderStatus?: IOrderStatus;
  country?: ICountry;
  pharmaCompanyOrderDetails?: IPharmaCompanyOrderDetail[] | null;
  pharmaCompanyOrderTenants?: IPharmaCompanyOrderTenant[] | null;
  botCaseTenantProducts?: IBotCaseTenantProduct[] | null;
  botSessionTenantProductActions?: IBotSessionTenantProductAction[] | null;
}

export interface IPharmaCompanyOrderDetail {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  pharmaCompanyOrderID?: string | null;
  productID?: string | null;
  /** @format double */
  maxPricePerDay?: number | null;
  /** @format double */
  priceForView?: number | null;
  /** @format double */
  priceForClick?: number | null;
  /** @format double */
  priceForCart?: number | null;
  /** @format int32 */
  positionIndex?: number;
  pharmaCompanyOrder?: IPharmaCompanyOrder;
  product?: IProduct;
}

export interface IPharmaCompanyOrderDetailDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IPharmaCompanyOrderDetail[];
}

export interface IPharmaCompanyOrderDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IPharmaCompanyOrder[];
}

export interface IPharmaCompanyOrderTenant {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  pharmaCompanyOrderID?: string | null;
  tenantID?: string | null;
  pharmaCompanyOrder?: IPharmaCompanyOrder;
  tenant?: ITenant;
}

export interface IPharmaCompanyOrderTenantBulk {
  pharmaCompanyOrderID?: string | null;
  tenantIDs?: string[] | null;
}

export interface IPharmaCompanyOrderTenantDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IPharmaCompanyOrderTenant[];
}

export interface IProduct {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  drugRegNumber?: string | null;
  mainMedicationID?: string | null;
  productBrandID?: string | null;
  dosageFormID?: string | null;
  genderID?: string | null;
  pharmaCompanyID?: string | null;
  countryID?: string | null;
  catalogName?: string | null;
  /** @format double */
  quantity?: number | null;
  /** @format double */
  fromAge?: number;
  /** @format double */
  toAge?: number;
  isBrand?: boolean;
  isGeneric?: boolean;
  isOverTheCounter?: boolean;
  useForVitAssist?: boolean;
  useForPharmAssist?: boolean;
  signatureApprovalMain?: boolean;
  signatureApprovalSecondary?: boolean;
  gender?: IGender;
  productBrand?: IProductBrand;
  mainMedication?: IMedication;
  dosageForm?: IDosageForm;
  pharmaCompany?: IPharmaCompany;
  country?: ICountry;
  productContents?: IProductContent[] | null;
  filterProductSymptoms?: IProductSymptom[] | null;
  filterProductMedicalConditions?: IProductMedicalCondition[] | null;
  filterProductSensitivities?: IProductSensitivity[] | null;
  productIngredients?: IProductIngredient[] | null;
  filterProductMedications?: IProductMedication[] | null;
  productDietaryPreferences?: IProductDietaryPreference[] | null;
  tenantProducts?: ITenantProduct[] | null;
  productCases?: IProductCase[] | null;
  pharmaCompanyOrderDetails?: IPharmaCompanyOrderDetail[] | null;
  productLeaflets?: IProductLeaflet[] | null;
}

export interface IProductBrand {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  products?: IProduct[] | null;
  productBrandContents?: IProductBrandContent[] | null;
  botUserProfiles?: IBotUserProfile[] | null;
  tenantProductBrands?: ITenantProductBrand[] | null;
}

export interface IProductBrandContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  productBrandID?: string | null;
  productBrand?: IProductBrand;
}

export interface IProductBrandContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductBrandContent[];
}

export interface IProductBrandDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductBrand[];
}

export interface IProductCase {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productID?: string | null;
  caseID?: string | null;
  product?: IProduct;
  case?: ICase;
}

export interface IProductCaseDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductCase[];
}

export interface IProductContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  productID?: string | null;
  product?: IProduct;
}

export interface IProductContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductContent[];
}

export interface IProductDietaryPreference {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productID?: string | null;
  dietaryPreferenceID?: string | null;
  product?: IProduct;
  dietaryPreference?: IDietaryPreference;
}

export interface IProductDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProduct[];
}

export interface IProductIngredient {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productID?: string | null;
  sensitivityID?: string | null;
  product?: IProduct;
  sensitivity?: ISensitivity;
}

export interface IProductIngredientDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductIngredient[];
}

export interface IProductLeaflet {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  productID?: string | null;
  comments?: string | null;
  /** @format date-time */
  leafletUpdatedDate?: string | Date | null;
  filePath?: string | null;
  product?: IProduct;
}

export interface IProductLeafletDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductLeaflet[];
}

export interface IProductMedicalCondition {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productID?: string | null;
  medicalConditionID?: string | null;
  product?: IProduct;
  medicalCondition?: IMedicalCondition;
}

export interface IProductMedicalConditionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductMedicalCondition[];
}

export interface IProductMedication {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productID?: string | null;
  medicationID?: string | null;
  product?: IProduct;
  medication?: IMedication;
}

export interface IProductMedicationDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductMedication[];
}

export interface IProductSensitivity {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productID?: string | null;
  sensitivityID?: string | null;
  product?: IProduct;
  sensitivity?: ISensitivity;
}

export interface IProductSensitivityDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductSensitivity[];
}

export interface IProductSymptom {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productID?: string | null;
  symptomID?: string | null;
  product?: IProduct;
  symptom?: ISymptom;
}

export interface IProductsTailorFitCase {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  caseID?: string | null;
  casePageID?: string | null;
  /** @format int32 */
  rank?: number;
  productsTailorFitUserProfile?: IProductsTailorFitUserProfile;
  case?: ICase;
  casePage?: ICasePage;
}

export interface IProductsTailorFitCaseDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductsTailorFitCase[];
}

export interface IProductsTailorFitSession {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  tenantID?: string | null;
  appIdentityUserID?: string | null;
  uniqueSessionUserID?: string | null;
  genderID?: string | null;
  languageID?: string | null;
  caseID?: string | null;
  productsTailorFitSessionStepID?: string | null;
  /** @format double */
  age?: number | null;
  flowType?: string | null;
  reference?: string | null;
  url?: string | null;
  location?: string | null;
  longitude?: string | null;
  latitude?: string | null;
  deviceType?: string | null;
  tenant?: ITenant;
  case?: ICase;
  gender?: IGender;
  language?: ILanguage;
  productsTailorFitUserProfile?: IProductsTailorFitUserProfile;
  productsTailorFitSessionStep?: IProductsTailorFitSessionStep;
  productsTailorFitSessionLogs?: IProductsTailorFitSessionLog[] | null;
  productsTailorFitSessionDosageForms?: IProductsTailorFitSessionDosageForm[] | null;
  productsTailorFitSessionProducts?: IProductsTailorFitSessionProduct[] | null;
}

export interface IProductsTailorFitSessionDosageForm {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productsTailorFitSessionID?: string | null;
  dosageFormID?: string | null;
  chosen?: boolean;
  dosageForm?: IDosageForm;
  productsTailorFitSession?: IProductsTailorFitSession;
}

export interface IProductsTailorFitSessionDosageFormDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductsTailorFitSessionDosageForm[];
}

export interface IProductsTailorFitSessionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductsTailorFitSession[];
}

export interface IProductsTailorFitSessionLog {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productsTailorFitSessionID?: string | null;
  /** @format date-time */
  eventDate?: string | Date;
  /** @format int32 */
  orderIndex?: number;
  flowType?: string | null;
  caseID?: string | null;
  userActionType?: string | null;
  userActionKey?: string | null;
  userActionValue?: string | null;
  userActionEntityID?: string | null;
  productsTailorFitSession?: IProductsTailorFitSession;
}

export interface IProductsTailorFitSessionLogDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductsTailorFitSessionLog[];
}

export interface IProductsTailorFitSessionProduct {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  productsTailorFitSessionID?: string | null;
  tenantProductID?: string | null;
  /** @format int32 */
  positionIndex?: number;
  isPromoted?: boolean;
  productsTailorFitSession?: IProductsTailorFitSession;
  tenantProduct?: ITenantProduct;
}

export interface IProductsTailorFitSessionProductDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductsTailorFitSessionProduct[];
}

export interface IProductsTailorFitSessionStep {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  productsTailorFitSessionStepOption?: IProductsTailorFitSessionStepOption;
  isSessionComplete?: boolean;
  productsTailorFitSessions?: IProductsTailorFitSession[] | null;
}

export interface IProductsTailorFitSessionStepDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductsTailorFitSessionStep[];
}

export enum IProductsTailorFitSessionStepOption {
  Init = 'Init',
  Cases = 'Cases',
  ProductsDiscovery = 'ProductsDiscovery',
}

export interface IProductsTailorFitTheme {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  description?: string | null;
  textPrimary?: string | null;
  textSecondary?: string | null;
  warningColor?: string | null;
  warningContrastColor?: string | null;
  errorColor?: string | null;
  errorContrastColor?: string | null;
  priceColor?: string | null;
  primaryColor?: string | null;
  primaryContrastColor?: string | null;
  primaryGradientColor?: string | null;
  secondaryColor?: string | null;
  secondaryContrastColor?: string | null;
  secondaryGradientColor?: string | null;
  bgPrimary?: string | null;
  bgSecondary?: string | null;
  productsTailorFitUserProfiles?: IProductsTailorFitUserProfile[] | null;
}

export interface IProductsTailorFitThemeDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductsTailorFitTheme[];
}

export interface IProductsTailorFitUserProfile {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  appIdentityUserID?: string | null;
  languageID?: string | null;
  tenantID?: string | null;
  genderID?: string | null;
  productsTailorFitThemeID?: string | null;
  /** @format double */
  fromAge?: number;
  /** @format double */
  toAge?: number;
  propertyCode?: string | null;
  secret?: string | null;
  currency?: string | null;
  htmlTagContainer?: string | null;
  cssInjection?: string | null;
  brandName?: string | null;
  /** @format int32 */
  zIndex?: number;
  applyAddToCart?: boolean;
  addToCartCode?: string | null;
  trackLog?: boolean;
  internal?: boolean;
  dispalyAge?: boolean;
  displayExternalVendor?: boolean;
  displayProductZooming?: boolean;
  displayPreferences?: boolean;
  displayOpenTheBot?: boolean;
  appIdentityUser?: IAppIdentityUser;
  gender?: IGender;
  tenant?: ITenant;
  language?: ILanguage;
  productsTailorFitTheme?: IProductsTailorFitTheme;
  productsTailorFitCases?: IProductsTailorFitCase[] | null;
  productsTailorFitUserProfileContents?: IProductsTailorFitUserProfileContent[] | null;
  productsTailorFitSessions?: IProductsTailorFitSession[] | null;
}

export interface IProductsTailorFitUserProfileContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  languageID?: string | null;
  /** @format int32 */
  containerMaxWidth?: number;
  title?: string | null;
  subTitle?: string | null;
  ageAndGenderLabel?: string | null;
  icon?: string | null;
  banner?: string | null;
  logo?: string | null;
  disclaimer?: string | null;
  termsAndConditionsHtmlContent?: string | null;
  privacyPolicyHtmlContent?: string | null;
  termsAndPrivacyLabel?: string | null;
  openBotLabel?: string | null;
  tipsLabel?: string | null;
  productSubTitleLabel?: string | null;
  language?: ILanguage;
  productsTailorFitUserProfile?: IProductsTailorFitUserProfile;
}

export interface IProductsTailorFitUserProfileContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductsTailorFitUserProfileContent[];
}

export interface IProductsTailorFitUserProfileDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IProductsTailorFitUserProfile[];
}

export enum IRFType {
  YesNo = 'YesNo',
  PainScale = 'PainScale',
  Survey = 'Survey',
}

export interface IRedFlag {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  redFlagPageID?: string | null;
  redFlagTypeID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  catalogName?: string | null;
  genderID?: string | null;
  gender?: IGender;
  defaultIsYes?: boolean | null;
  defaultIsNo?: boolean | null;
  referOnYes?: boolean | null;
  referOnNo?: boolean | null;
  /** @format int32 */
  referFromScore?: number | null;
  redFlagOptions?: IRedFlagOption[] | null;
  redFlagPage?: IRedFlagPage;
  redFlagType?: IRedFlagType;
  redFlagContents?: IRedFlagContent[] | null;
}

export interface IRedFlagContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  redFlagID?: string | null;
  redFlag?: IRedFlag;
}

export interface IRedFlagContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedFlagContent[];
}

export interface IRedFlagDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedFlag[];
}

export interface IRedFlagOption {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  redFlagID?: string | null;
  /** @format int32 */
  orderNumber?: number;
  catalogName?: string | null;
  /** @format int32 */
  score?: number;
  color?: string | null;
  redFlag?: IRedFlag;
  redFlagOptionContents?: IRedFlagOptionContent[] | null;
}

export interface IRedFlagOptionContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  redFlagOptionID?: string | null;
  redFlagOption?: IRedFlagOption;
}

export interface IRedFlagOptionContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedFlagOptionContent[];
}

export interface IRedFlagOptionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedFlagOption[];
}

export interface IRedFlagPage {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  casePageID?: string | null;
  /** @format int32 */
  pageNumber?: number;
  catalogName?: string | null;
  /** @format int32 */
  minScoreToRefer?: number | null;
  casePage?: ICasePage;
  redFlagPageContents?: IRedFlagPageContent[] | null;
  redFlags?: IRedFlag[] | null;
}

export interface IRedFlagPageContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  redFlagPageID?: string | null;
  redFlagPage?: IRedFlagPage;
}

export interface IRedFlagPageContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedFlagPageContent[];
}

export interface IRedFlagPageDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedFlagPage[];
}

export interface IRedFlagType {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  rfType?: IRFType;
  displayOptions?: boolean;
  redFlags?: IRedFlag[] | null;
}

export interface IRedFlagTypeDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedFlagType[];
}

export interface IRedisParam {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  source?: string | null;
  key?: string | null;
  description?: string | null;
  tenantID?: string | null;
  caseID?: string | null;
  languageID?: string | null;
  /** @format date-time */
  lastUpdatedDate?: string | Date;
  case?: ICase;
  tenant?: ITenant;
  language?: ILanguage;
}

export interface IRedisParamDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IRedisParam[];
}

export interface IRelationshipBulk {
  mainID?: string | null;
  itemIDs?: string[] | null;
}

export interface IReportResponse {
  /** @format int32 */
  numberOfBots?: number;
  /** @format double */
  botPrice?: number;
  /** @format double */
  totalBotPrice?: number;
  /** @format double */
  scenarioPackagePrice?: number;
  /** @format int32 */
  numberOfCompleteSessions?: number;
  /** @format double */
  completeSessionPrice?: number;
  /** @format double */
  totalCompleteSessionPrice?: number;
}

export interface ISearchEngineOptimization {
  metaTitle?: string | null;
  metaDescription?: string | null;
  metaKeyWords?: string | null;
}

export enum ISectionType {
  Section1 = 'Section1',
  Section2 = 'Section2',
}

export interface ISensitivity {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  useForVitAssist?: boolean;
  useForPharmAssist?: boolean;
  isMultiVitamin?: boolean;
  sensitivityContents?: ISensitivityContent[] | null;
  caseSensitivities?: ICaseSensitivity[] | null;
  productSensitivities?: IProductSensitivity[] | null;
  botSessionSensitivities?: IBotSessionSensitivity[] | null;
  productIngredients?: IProductIngredient[] | null;
  medicalConditionIngredients?: IMedicalConditionIngredient[] | null;
}

export interface ISensitivityContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  sensitivityID?: string | null;
  sensitivity?: ISensitivity;
}

export interface ISensitivityContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ISensitivityContent[];
}

export interface ISensitivityDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ISensitivity[];
}

export enum ISessionStep {
  Init = 'Init',
  Information = 'Information',
  StartFlow = 'StartFlow',
  Refer = 'Refer',
  Stop = 'Stop',
  MidFlow = 'MidFlow',
  CompleteFlow = 'CompleteFlow',
  CreateUser = 'CreateUser',
  ProductsDiscovery = 'ProductsDiscovery',
}

export interface ISiteParameter {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  htmlContent?: string | null;
  copyright?: string | null;
  logo?: string | null;
  slogan?: string | null;
  videoURL?: string | null;
  phone1?: string | null;
  phone2?: string | null;
  email?: string | null;
  address?: string | null;
  bannerPicture?: string | null;
  bannerPictureSmall?: string | null;
  bannerLinkURL?: string | null;
  displayBanner?: boolean;
  metaTitle?: string | null;
  metaDescription?: string | null;
  metaKeyWords?: string | null;
  canonical?: string | null;
  language?: ILanguage;
  siteParameterSocialMedias?: ISiteParameterSocialMedia[] | null;
}

export interface ISiteParameterSocialMedia {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  siteParameterID?: string | null;
  icon1?: string | null;
  icon2?: string | null;
  linkURL?: string | null;
  description?: string | null;
  /** @format int32 */
  rank?: number;
  siteParameter?: ISiteParameter;
}

export interface ISocialMedia {
  icon?: string | null;
  linkURL?: string | null;
  description?: string | null;
  /** @format int32 */
  rank?: number;
}

export interface ISymptom {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  catalogName?: string | null;
  genderID?: string | null;
  gender?: IGender;
  symptomContents?: ISymptomContent[] | null;
  caseSymptoms?: ICaseSymptom[] | null;
  productSymptoms?: IProductSymptom[] | null;
}

export interface ISymptomContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  symptomID?: string | null;
  symptom?: ISymptom;
}

export interface ISyncProductsRequest {
  tenantID?: string | null;
}

export interface ITenant {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  countryID?: string | null;
  companyName?: string | null;
  logo?: string | null;
  /** @format double */
  botPrice?: number | null;
  /** @format double */
  scenarioPackagePrice?: number | null;
  /** @format double */
  completeSessionPrice?: number | null;
  fullStoryCode?: string | null;
  googleAnalyticsCode?: string | null;
  country?: ICountry;
  tenantUserFamilyProfiles?: ITenantUserFamilyProfile[] | null;
  tenantProducts?: ITenantProduct[] | null;
  tenantIgnoreProducts?: ITenantIgnoreProduct[] | null;
  userTenants?: IUserTenant[] | null;
  botUserProfiles?: IBotUserProfile[] | null;
  productsTailorFitUserProfiles?: IProductsTailorFitUserProfile[] | null;
  botSessions?: IBotSession[] | null;
  productsTailorFitSessions?: IProductsTailorFitSession[] | null;
  tenantProductBrands?: ITenantProductBrand[] | null;
  pharmaCompanyOrderTenants?: IPharmaCompanyOrderTenant[] | null;
  tenantBotContents?: ITenantBotContent[] | null;
  redisParams?: IRedisParam[] | null;
}

export interface ITenantBotContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  tenantID?: string | null;
  languageID?: string | null;
  welcomeTitle?: string | null;
  welcomeSubTitle?: string | null;
  welcomeMessage?: string | null;
  welcomeBanner?: string | null;
  logo?: string | null;
  disclaimer?: string | null;
  termsAndConditions?: string | null;
  privacyPolicy?: string | null;
  termsAndConditionsHtmlContent?: string | null;
  privacyPolicyHtmlContent?: string | null;
  termsAndPrivacyLabel?: string | null;
  helpMeLabel?: string | null;
  productSubTitleLabel?: string | null;
  language?: ILanguage;
  tenant?: ITenant;
}

export interface ITenantBotContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ITenantBotContent[];
}

export interface ITenantBotContentItem {
  tenantID?: string | null;
  languageID?: string | null;
  welcomeTitle?: string | null;
  welcomeSubTitle?: string | null;
  welcomeMessage?: string | null;
  welcomeBanner?: string | null;
  logo?: string | null;
  disclaimer?: string | null;
  termsAndConditions?: string | null;
  privacyPolicy?: string | null;
  termsAndPrivacyLabel?: string | null;
  helpMeLabel?: string | null;
  productSubTitleLabel?: string | null;
  id?: string | null;
}

export interface ITenantBotContentItemBase {
  tenantID?: string | null;
  languageID?: string | null;
  welcomeTitle?: string | null;
  welcomeSubTitle?: string | null;
  welcomeMessage?: string | null;
  welcomeBanner?: string | null;
  logo?: string | null;
  disclaimer?: string | null;
  termsAndConditions?: string | null;
  privacyPolicy?: string | null;
  termsAndPrivacyLabel?: string | null;
  helpMeLabel?: string | null;
  productSubTitleLabel?: string | null;
}

export interface ITenantDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ITenant[];
}

export interface ITenantIgnoreProduct {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  tenantID?: string | null;
  productName?: string | null;
  serialNumber?: string | null;
  tenant?: ITenant;
}

export interface ITenantIgnoreProductDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ITenantIgnoreProduct[];
}

export interface ITenantProduct {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  tenantID?: string | null;
  productID?: string | null;
  shopProductID?: string | null;
  serialNumber?: string | null;
  linkURL?: string | null;
  /** @format double */
  price?: number | null;
  /** @format double */
  priceSale?: number | null;
  priceSaleDescription?: string | null;
  /** @format int32 */
  shopViews?: number;
  isPending?: boolean;
  isExternalVendor?: boolean;
  tenant?: ITenant;
  product?: IProduct;
  botSessionProducts?: IBotSessionProduct[] | null;
  productsTailorFitSessionProducts?: IProductsTailorFitSessionProduct[] | null;
  tenantProductContents?: ITenantProductContent[] | null;
  botCaseTenantProducts?: IBotCaseTenantProduct[] | null;
  botSessionTenantProductActions?: IBotSessionTenantProductAction[] | null;
}

export interface ITenantProductBrand {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  tenantID?: string | null;
  productBrandID?: string | null;
  /** @format int32 */
  rank?: number;
  tenant?: ITenant;
  productBrand?: IProductBrand;
}

export interface ITenantProductContent {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  languageID?: string | null;
  title?: string | null;
  description?: string | null;
  html?: string | null;
  htmlContent?: string | null;
  icon?: string | null;
  media?: string | null;
  language?: ILanguage;
  tenantProductID?: string | null;
  tenantProduct?: ITenantProduct;
}

export interface ITenantProductContentDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ITenantProductContent[];
}

export interface ITenantProductDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ITenantProduct[];
}

export interface ITenantRequest {
  tenantID?: string | null;
  logo?: string | null;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  googleAnalyticsCode?: string | null;
  fullStoryCode?: string | null;
}

export interface ITenantResponse {
  logo?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  companyName?: string | null;
  googleAnalyticsCode?: string | null;
  fullStoryCode?: string | null;
  tenantBotContentItems?: ITenantBotContentItem[] | null;
}

export interface ITenantUserFamilyProfile {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  tenantID?: string | null;
  appIdentityUserID?: string | null;
  tenant?: ITenant;
  userFamilyProfile?: IUserFamilyProfile;
}

export interface IUserCrmProfile {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  userCrmProfilePermissionID?: string | null;
  languageID?: string | null;
  /** @format date-time */
  generatePasswordEndDate?: string | Date | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  userPhoto?: string | null;
  appIdentityUser?: IAppIdentityUser;
  userCrmProfilePermission?: IUserCrmProfilePermission;
  language?: ILanguage;
}

export interface IUserCrmProfilePermission {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  title?: string | null;
  isAllowToEditWebsites?: boolean;
  isAllowToEditSetting?: boolean;
  isAllowToEditTenants?: boolean;
  isAllowToEditFlows?: boolean;
  isAllowToEditLabels?: boolean;
  isAllowToEditProfile?: boolean;
  isAllowToEditCRMUsers?: boolean;
  isAllowToEditPermissions?: boolean;
  isAllowToViewLog?: boolean;
  isAllowToViewSensitiveInformation?: boolean;
  isAllowToResetSensitiveInformation?: boolean;
  userCrmProfiles?: IUserCrmProfile[] | null;
}

export interface IUserFamilyProfile {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  languageID?: string | null;
  genderID?: string | null;
  /** @format date-time */
  generatePasswordEndDate?: string | Date | null;
  email?: string | null;
  mobilePhone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  /** @format date-time */
  dateOfBirth?: string | Date | null;
  userPhoto?: string | null;
  gender?: IGender;
  appIdentityUser?: IAppIdentityUser;
  language?: ILanguage;
  familyMembers?: IFamilyMember[] | null;
  tenantUserFamilyProfiles?: ITenantUserFamilyProfile[] | null;
  botSessions?: IBotSession[] | null;
}

export interface IUserProfileTenant {
  tenantID?: string | null;
  tenantName?: string | null;
  logo?: string | null;
}

export interface IUserTenant {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  tenantID?: string | null;
  appIdentityUserID?: string | null;
  tenant?: ITenant;
  userTenantProfile?: IUserTenantProfile;
}

export interface IUserTenantProfile {
  id?: string | null;
  /** @format date-time */
  createdDate?: string | Date;
  /** @format date-time */
  updatedDate?: string | Date | null;
  isActive?: boolean;
  appIdentityUserID?: string | null;
  /** @format date-time */
  generatePasswordEndDate?: string | Date | null;
  email?: string | null;
  mobilePhone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  isAdmin?: boolean;
  fullName?: string | null;
  appIdentityUser?: IAppIdentityUser;
  userTenants?: IUserTenant[] | null;
}

export interface IBodyPartContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBodyPartsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotCasesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotCaseTenantProductsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotDomainsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotSessionDosageFormsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotSessionLogsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotSessionMedicalConditionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotSessionProductsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotSessionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotSessionSensitivitiesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotSessionStepsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotSessionTenantProductActionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotThemesGetBotThemesDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotThemesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotUserProfilesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBotWelcomeMessagesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseBodyPartsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseCategoriesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseCategoryContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseComputeMedicalConditionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseDecisionContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseDecisionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseDosageFormsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseMedicalConditionGroupContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseMedicalConditionGroupsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseMedicalConditionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseMedicationsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICasePageContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICasePagesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICasePageTypesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICasesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseSensitivitiesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICaseTagsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICountriesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ICountryLanguagesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDosageFormCategoriesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDosageFormCategoryContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDosageFormContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDosageFormsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDosageFormUnitTypeContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDosageFormUnitTypesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IGenderContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IGendersGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ILabelsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ILanguagesGetAllMajorDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ILanguagesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMediaUploadsRemoveFileFromCloudUpdateParams {
  filePath?: string;
  isImage?: boolean;
}

export interface IMedicalConditionContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMedicalConditionIngredientContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMedicalConditionIngredientsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMedicalConditionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMedicationContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMedicationsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IOrderStatusesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IPharmaCompaniesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IPharmaCompaniesGetPharmaCompaniesDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IPharmaCompanyOrderDetailsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IPharmaCompanyOrdersGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IPharmaCompanyOrderTenantsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductBrandContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductBrandsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductCasesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductIngredientsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductLeafletsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductMedicalConditionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductMedicationsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductSensitivitiesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductsTailorFitCasesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductsTailorFitSessionDosageFormsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductsTailorFitSessionLogsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductsTailorFitSessionProductsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductsTailorFitSessionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductsTailorFitSessionStepsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductsTailorFitThemesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductsTailorFitUserProfileContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IProductsTailorFitUserProfilesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedFlagContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedFlagOptionContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedFlagOptionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedFlagPageContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedFlagPagesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedFlagsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedFlagTypesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IRedisParamsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISensitivitiesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISensitivityContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISessionsGetSessionsDynamicListParams {
  TenantID?: string;
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISessionsGetSessionLogsDynamicListParams {
  SessionID?: string;
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISessionsGetSessionDosageFormsDynamicListParams {
  SessionID?: string;
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISessionsGetSessionProductsDynamicListParams {
  SessionID?: string;
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISessionsGetSessionMedicalConditionsDynamicListParams {
  SessionID?: string;
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISessionsGetSessionSensitivitiesDynamicListParams {
  SessionID?: string;
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ITenantBotContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ITenantIgnoreProductsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ITenantProductContentsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ITenantProductsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ITenantsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ITenantsGetTenantsDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title ApoWiser Customer-Portal API
 * @version v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsGeneratePasswordCreate
     * @request POST:/api/Accounts/GeneratePassword
     * @secure
     */
    accountsGeneratePasswordCreate: (data: IGeneratePasswordRequest, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Accounts/GeneratePassword`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsLoginWithCodeCreate
     * @request POST:/api/Accounts/LoginWithCode
     * @secure
     */
    accountsLoginWithCodeCreate: (data: ILoginRequest, params: RequestParams = {}) =>
      this.request<ILoginResponse, any>({
        path: `/api/Accounts/LoginWithCode`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsLogoutCreate
     * @request POST:/api/Accounts/Logout
     * @secure
     */
    accountsLogoutCreate: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Accounts/Logout`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsRefreshTokenCreate
     * @request POST:/api/Accounts/RefreshToken
     * @secure
     */
    accountsRefreshTokenCreate: (data: IJwt, params: RequestParams = {}) =>
      this.request<IJwt, any>({
        path: `/api/Accounts/RefreshToken`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsGetCurrentAppUserList
     * @request GET:/api/Accounts/GetCurrentAppUser
     * @secure
     */
    accountsGetCurrentAppUserList: (params: RequestParams = {}) =>
      this.request<ICurrentUserProfileResponse, any>({
        path: `/api/Accounts/GetCurrentAppUser`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BodyPartContents
     * @name BodyPartContentsGetAllDynamicList
     * @request GET:/api/BodyPartContents/GetAllDynamic
     * @secure
     */
    bodyPartContentsGetAllDynamicList: (
      query: IBodyPartContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBodyPartContentDynamicResponse, any>({
        path: `/api/BodyPartContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BodyParts
     * @name BodyPartsGetAllDynamicList
     * @request GET:/api/BodyParts/GetAllDynamic
     * @secure
     */
    bodyPartsGetAllDynamicList: (
      query: IBodyPartsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBodyPartDynamicResponse, any>({
        path: `/api/BodyParts/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotCases
     * @name BotCasesGetAllDynamicList
     * @request GET:/api/BotCases/GetAllDynamic
     * @secure
     */
    botCasesGetAllDynamicList: (
      query: IBotCasesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotCaseDynamicResponse, any>({
        path: `/api/BotCases/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotCases
     * @name BotCasesCreateCreate
     * @request POST:/api/BotCases/Create
     * @secure
     */
    botCasesCreateCreate: (data: IBotCase, params: RequestParams = {}) =>
      this.request<IBotCase, any>({
        path: `/api/BotCases/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotCases
     * @name BotCasesPatchPartialUpdate
     * @request PATCH:/api/BotCases/Patch/{id}
     * @secure
     */
    botCasesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BotCases/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotCases
     * @name BotCasesDeleteDelete
     * @request DELETE:/api/BotCases/Delete/{id}
     * @secure
     */
    botCasesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IBotCase, any>({
        path: `/api/BotCases/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotCaseTenantProducts
     * @name BotCaseTenantProductsGetAllDynamicList
     * @request GET:/api/BotCaseTenantProducts/GetAllDynamic
     * @secure
     */
    botCaseTenantProductsGetAllDynamicList: (
      query: IBotCaseTenantProductsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotCaseTenantProductDynamicResponse, any>({
        path: `/api/BotCaseTenantProducts/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotCaseTenantProducts
     * @name BotCaseTenantProductsCreateCreate
     * @request POST:/api/BotCaseTenantProducts/Create
     * @secure
     */
    botCaseTenantProductsCreateCreate: (data: IBotCaseTenantProduct, params: RequestParams = {}) =>
      this.request<IBotCaseTenantProduct, any>({
        path: `/api/BotCaseTenantProducts/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotCaseTenantProducts
     * @name BotCaseTenantProductsPatchPartialUpdate
     * @request PATCH:/api/BotCaseTenantProducts/Patch/{id}
     * @secure
     */
    botCaseTenantProductsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/BotCaseTenantProducts/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotCaseTenantProducts
     * @name BotCaseTenantProductsDeleteDelete
     * @request DELETE:/api/BotCaseTenantProducts/Delete/{id}
     * @secure
     */
    botCaseTenantProductsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IBotCaseTenantProduct, any>({
        path: `/api/BotCaseTenantProducts/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotDomains
     * @name BotDomainsGetAllDynamicList
     * @request GET:/api/BotDomains/GetAllDynamic
     * @secure
     */
    botDomainsGetAllDynamicList: (
      query: IBotDomainsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotDomainDynamicResponse, any>({
        path: `/api/BotDomains/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotDomains
     * @name BotDomainsCreateCreate
     * @request POST:/api/BotDomains/Create
     * @secure
     */
    botDomainsCreateCreate: (data: IBotDomain, params: RequestParams = {}) =>
      this.request<IBotDomain, any>({
        path: `/api/BotDomains/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotDomains
     * @name BotDomainsPatchPartialUpdate
     * @request PATCH:/api/BotDomains/Patch/{id}
     * @secure
     */
    botDomainsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BotDomains/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotDomains
     * @name BotDomainsDeleteDelete
     * @request DELETE:/api/BotDomains/Delete/{id}
     * @secure
     */
    botDomainsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IBotDomain, any>({
        path: `/api/BotDomains/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotSessionDosageForms
     * @name BotSessionDosageFormsGetAllDynamicList
     * @request GET:/api/BotSessionDosageForms/GetAllDynamic
     * @secure
     */
    botSessionDosageFormsGetAllDynamicList: (
      query: IBotSessionDosageFormsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotSessionDosageFormDynamicResponse, any>({
        path: `/api/BotSessionDosageForms/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotSessionLogs
     * @name BotSessionLogsGetAllDynamicList
     * @request GET:/api/BotSessionLogs/GetAllDynamic
     * @secure
     */
    botSessionLogsGetAllDynamicList: (
      query: IBotSessionLogsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotSessionLogDynamicResponse, any>({
        path: `/api/BotSessionLogs/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotSessionMedicalConditions
     * @name BotSessionMedicalConditionsGetAllDynamicList
     * @request GET:/api/BotSessionMedicalConditions/GetAllDynamic
     * @secure
     */
    botSessionMedicalConditionsGetAllDynamicList: (
      query: IBotSessionMedicalConditionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotSessionMedicalConditionDynamicResponse, any>({
        path: `/api/BotSessionMedicalConditions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotSessionProducts
     * @name BotSessionProductsGetAllDynamicList
     * @request GET:/api/BotSessionProducts/GetAllDynamic
     * @secure
     */
    botSessionProductsGetAllDynamicList: (
      query: IBotSessionProductsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotSessionProductDynamicResponse, any>({
        path: `/api/BotSessionProducts/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotSessions
     * @name BotSessionsGetAllDynamicList
     * @request GET:/api/BotSessions/GetAllDynamic
     * @secure
     */
    botSessionsGetAllDynamicList: (
      query: IBotSessionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotSessionDynamicResponse, any>({
        path: `/api/BotSessions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotSessionSensitivities
     * @name BotSessionSensitivitiesGetAllDynamicList
     * @request GET:/api/BotSessionSensitivities/GetAllDynamic
     * @secure
     */
    botSessionSensitivitiesGetAllDynamicList: (
      query: IBotSessionSensitivitiesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotSessionSensitivityDynamicResponse, any>({
        path: `/api/BotSessionSensitivities/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotSessionSteps
     * @name BotSessionStepsGetAllDynamicList
     * @request GET:/api/BotSessionSteps/GetAllDynamic
     * @secure
     */
    botSessionStepsGetAllDynamicList: (
      query: IBotSessionStepsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotSessionStepDynamicResponse, any>({
        path: `/api/BotSessionSteps/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotSessionTenantProductActions
     * @name BotSessionTenantProductActionsGetAllDynamicList
     * @request GET:/api/BotSessionTenantProductActions/GetAllDynamic
     * @secure
     */
    botSessionTenantProductActionsGetAllDynamicList: (
      query: IBotSessionTenantProductActionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotSessionTenantProductActionDynamicResponse, any>({
        path: `/api/BotSessionTenantProductActions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotsHelper
     * @name BotsHelperGetTotalNewCasesDetail
     * @request GET:/api/BotsHelper/GetTotalNewCases/{botID}
     * @secure
     */
    botsHelperGetTotalNewCasesDetail: (botId: string, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/BotsHelper/GetTotalNewCases/${botId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotsHelper
     * @name BotsHelperSyncCreate
     * @request POST:/api/BotsHelper/Sync/{botID}
     * @secure
     */
    botsHelperSyncCreate: (botId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BotsHelper/Sync/${botId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotsHelper
     * @name BotsHelperCreateBotWelcomeMessagesCreate
     * @request POST:/api/BotsHelper/CreateBotWelcomeMessages/{botID}
     * @secure
     */
    botsHelperCreateBotWelcomeMessagesCreate: (botId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BotsHelper/CreateBotWelcomeMessages/${botId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotsHelper
     * @name BotsHelperGeneratePropertyCodeList
     * @request GET:/api/BotsHelper/GeneratePropertyCode
     * @secure
     */
    botsHelperGeneratePropertyCodeList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BotsHelper/GeneratePropertyCode`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotThemes
     * @name BotThemesGetBotThemesDynamicList
     * @request GET:/api/BotThemes/GetBotThemesDynamic
     * @secure
     */
    botThemesGetBotThemesDynamicList: (
      query: IBotThemesGetBotThemesDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/BotThemes/GetBotThemesDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotThemes
     * @name BotThemesGetAllDynamicList
     * @request GET:/api/BotThemes/GetAllDynamic
     * @secure
     */
    botThemesGetAllDynamicList: (
      query: IBotThemesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotThemeDynamicResponse, any>({
        path: `/api/BotThemes/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotThemes
     * @name BotThemesCreateCreate
     * @request POST:/api/BotThemes/Create
     * @secure
     */
    botThemesCreateCreate: (data: IBotTheme, params: RequestParams = {}) =>
      this.request<IBotTheme, any>({
        path: `/api/BotThemes/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotThemes
     * @name BotThemesPatchPartialUpdate
     * @request PATCH:/api/BotThemes/Patch/{id}
     * @secure
     */
    botThemesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BotThemes/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotThemes
     * @name BotThemesDeleteDelete
     * @request DELETE:/api/BotThemes/Delete/{id}
     * @secure
     */
    botThemesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IBotTheme, any>({
        path: `/api/BotThemes/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotUserProfiles
     * @name BotUserProfilesDeleteDelete
     * @request DELETE:/api/BotUserProfiles/Delete/{appUserID}
     * @secure
     */
    botUserProfilesDeleteDelete: (appUserId: string, params: RequestParams = {}) =>
      this.request<IBotUserProfile, any>({
        path: `/api/BotUserProfiles/Delete/${appUserId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotUserProfiles
     * @name BotUserProfilesGetAllDynamicList
     * @request GET:/api/BotUserProfiles/GetAllDynamic
     * @secure
     */
    botUserProfilesGetAllDynamicList: (
      query: IBotUserProfilesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotUserProfileDynamicResponse, any>({
        path: `/api/BotUserProfiles/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotUserProfiles
     * @name BotUserProfilesCreateCreate
     * @request POST:/api/BotUserProfiles/Create
     * @secure
     */
    botUserProfilesCreateCreate: (data: IBotUserProfile, params: RequestParams = {}) =>
      this.request<IBotUserProfile, any>({
        path: `/api/BotUserProfiles/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotUserProfiles
     * @name BotUserProfilesPatchPartialUpdate
     * @request PATCH:/api/BotUserProfiles/Patch/{appUserID}
     * @secure
     */
    botUserProfilesPatchPartialUpdate: (
      appUserId: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/BotUserProfiles/Patch/${appUserId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotWelcomeMessages
     * @name BotWelcomeMessagesGetAllDynamicList
     * @request GET:/api/BotWelcomeMessages/GetAllDynamic
     * @secure
     */
    botWelcomeMessagesGetAllDynamicList: (
      query: IBotWelcomeMessagesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotWelcomeMessageDynamicResponse, any>({
        path: `/api/BotWelcomeMessages/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotWelcomeMessages
     * @name BotWelcomeMessagesCreateCreate
     * @request POST:/api/BotWelcomeMessages/Create
     * @secure
     */
    botWelcomeMessagesCreateCreate: (data: IBotWelcomeMessage, params: RequestParams = {}) =>
      this.request<IBotWelcomeMessage, any>({
        path: `/api/BotWelcomeMessages/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotWelcomeMessages
     * @name BotWelcomeMessagesPatchPartialUpdate
     * @request PATCH:/api/BotWelcomeMessages/Patch/{id}
     * @secure
     */
    botWelcomeMessagesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/BotWelcomeMessages/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BotWelcomeMessages
     * @name BotWelcomeMessagesDeleteDelete
     * @request DELETE:/api/BotWelcomeMessages/Delete/{id}
     * @secure
     */
    botWelcomeMessagesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IBotWelcomeMessage, any>({
        path: `/api/BotWelcomeMessages/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseBodyParts
     * @name CaseBodyPartsGetAllDynamicList
     * @request GET:/api/CaseBodyParts/GetAllDynamic
     * @secure
     */
    caseBodyPartsGetAllDynamicList: (
      query: ICaseBodyPartsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseBodyPartDynamicResponse, any>({
        path: `/api/CaseBodyParts/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCategories
     * @name CaseCategoriesGetAllDynamicList
     * @request GET:/api/CaseCategories/GetAllDynamic
     * @secure
     */
    caseCategoriesGetAllDynamicList: (
      query: ICaseCategoriesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseCategoryDynamicResponse, any>({
        path: `/api/CaseCategories/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseCategoryContents
     * @name CaseCategoryContentsGetAllDynamicList
     * @request GET:/api/CaseCategoryContents/GetAllDynamic
     * @secure
     */
    caseCategoryContentsGetAllDynamicList: (
      query: ICaseCategoryContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseCategoryContentDynamicResponse, any>({
        path: `/api/CaseCategoryContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseComputeMedicalConditions
     * @name CaseComputeMedicalConditionsGetAllDynamicList
     * @request GET:/api/CaseComputeMedicalConditions/GetAllDynamic
     * @secure
     */
    caseComputeMedicalConditionsGetAllDynamicList: (
      query: ICaseComputeMedicalConditionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseComputeMedicalConditionDynamicResponse, any>({
        path: `/api/CaseComputeMedicalConditions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseContents
     * @name CaseContentsGetAllDynamicList
     * @request GET:/api/CaseContents/GetAllDynamic
     * @secure
     */
    caseContentsGetAllDynamicList: (
      query: ICaseContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseContentDynamicResponse, any>({
        path: `/api/CaseContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDecisionContents
     * @name CaseDecisionContentsGetAllDynamicList
     * @request GET:/api/CaseDecisionContents/GetAllDynamic
     * @secure
     */
    caseDecisionContentsGetAllDynamicList: (
      query: ICaseDecisionContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseDecisionContentDynamicResponse, any>({
        path: `/api/CaseDecisionContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDecisions
     * @name CaseDecisionsGetAllDynamicList
     * @request GET:/api/CaseDecisions/GetAllDynamic
     * @secure
     */
    caseDecisionsGetAllDynamicList: (
      query: ICaseDecisionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseDecisionDynamicResponse, any>({
        path: `/api/CaseDecisions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseDosageForms
     * @name CaseDosageFormsGetAllDynamicList
     * @request GET:/api/CaseDosageForms/GetAllDynamic
     * @secure
     */
    caseDosageFormsGetAllDynamicList: (
      query: ICaseDosageFormsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseDosageFormDynamicResponse, any>({
        path: `/api/CaseDosageForms/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditionGroupContents
     * @name CaseMedicalConditionGroupContentsGetAllDynamicList
     * @request GET:/api/CaseMedicalConditionGroupContents/GetAllDynamic
     * @secure
     */
    caseMedicalConditionGroupContentsGetAllDynamicList: (
      query: ICaseMedicalConditionGroupContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseMedicalConditionGroupContentDynamicResponse, any>({
        path: `/api/CaseMedicalConditionGroupContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditionGroups
     * @name CaseMedicalConditionGroupsGetAllDynamicList
     * @request GET:/api/CaseMedicalConditionGroups/GetAllDynamic
     * @secure
     */
    caseMedicalConditionGroupsGetAllDynamicList: (
      query: ICaseMedicalConditionGroupsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseMedicalConditionGroupDynamicResponse, any>({
        path: `/api/CaseMedicalConditionGroups/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedicalConditions
     * @name CaseMedicalConditionsGetAllDynamicList
     * @request GET:/api/CaseMedicalConditions/GetAllDynamic
     * @secure
     */
    caseMedicalConditionsGetAllDynamicList: (
      query: ICaseMedicalConditionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseMedicalConditionDynamicResponse, any>({
        path: `/api/CaseMedicalConditions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseMedications
     * @name CaseMedicationsGetAllDynamicList
     * @request GET:/api/CaseMedications/GetAllDynamic
     * @secure
     */
    caseMedicationsGetAllDynamicList: (
      query: ICaseMedicationsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseMedicationDynamicResponse, any>({
        path: `/api/CaseMedications/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePageContents
     * @name CasePageContentsGetAllDynamicList
     * @request GET:/api/CasePageContents/GetAllDynamic
     * @secure
     */
    casePageContentsGetAllDynamicList: (
      query: ICasePageContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICasePageContentDynamicResponse, any>({
        path: `/api/CasePageContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePages
     * @name CasePagesGetAllDynamicList
     * @request GET:/api/CasePages/GetAllDynamic
     * @secure
     */
    casePagesGetAllDynamicList: (
      query: ICasePagesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICasePageDynamicResponse, any>({
        path: `/api/CasePages/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CasePageTypes
     * @name CasePageTypesGetAllDynamicList
     * @request GET:/api/CasePageTypes/GetAllDynamic
     * @secure
     */
    casePageTypesGetAllDynamicList: (
      query: ICasePageTypesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICasePageTypeDynamicResponse, any>({
        path: `/api/CasePageTypes/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cases
     * @name CasesGetAllDynamicList
     * @request GET:/api/Cases/GetAllDynamic
     * @secure
     */
    casesGetAllDynamicList: (query: ICasesGetAllDynamicListParams, params: RequestParams = {}) =>
      this.request<ICaseDynamicResponse, any>({
        path: `/api/Cases/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseSensitivities
     * @name CaseSensitivitiesGetAllDynamicList
     * @request GET:/api/CaseSensitivities/GetAllDynamic
     * @secure
     */
    caseSensitivitiesGetAllDynamicList: (
      query: ICaseSensitivitiesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseSensitivityDynamicResponse, any>({
        path: `/api/CaseSensitivities/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CaseTags
     * @name CaseTagsGetAllDynamicList
     * @request GET:/api/CaseTags/GetAllDynamic
     * @secure
     */
    caseTagsGetAllDynamicList: (
      query: ICaseTagsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICaseTagDynamicResponse, any>({
        path: `/api/CaseTags/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Countries
     * @name CountriesGetAllDynamicList
     * @request GET:/api/Countries/GetAllDynamic
     * @secure
     */
    countriesGetAllDynamicList: (
      query: ICountriesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICountryDynamicResponse, any>({
        path: `/api/Countries/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountryLanguages
     * @name CountryLanguagesGetAllDynamicList
     * @request GET:/api/CountryLanguages/GetAllDynamic
     * @secure
     */
    countryLanguagesGetAllDynamicList: (
      query: ICountryLanguagesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ICountryLanguageDynamicResponse, any>({
        path: `/api/CountryLanguages/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormCategories
     * @name DosageFormCategoriesGetAllDynamicList
     * @request GET:/api/DosageFormCategories/GetAllDynamic
     * @secure
     */
    dosageFormCategoriesGetAllDynamicList: (
      query: IDosageFormCategoriesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDosageFormCategoryDynamicResponse, any>({
        path: `/api/DosageFormCategories/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormCategoryContents
     * @name DosageFormCategoryContentsGetAllDynamicList
     * @request GET:/api/DosageFormCategoryContents/GetAllDynamic
     * @secure
     */
    dosageFormCategoryContentsGetAllDynamicList: (
      query: IDosageFormCategoryContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDosageFormCategoryContentDynamicResponse, any>({
        path: `/api/DosageFormCategoryContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormContents
     * @name DosageFormContentsGetAllDynamicList
     * @request GET:/api/DosageFormContents/GetAllDynamic
     * @secure
     */
    dosageFormContentsGetAllDynamicList: (
      query: IDosageFormContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDosageFormContentDynamicResponse, any>({
        path: `/api/DosageFormContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageForms
     * @name DosageFormsGetAllDynamicList
     * @request GET:/api/DosageForms/GetAllDynamic
     * @secure
     */
    dosageFormsGetAllDynamicList: (
      query: IDosageFormsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDosageFormDynamicResponse, any>({
        path: `/api/DosageForms/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormUnitTypeContents
     * @name DosageFormUnitTypeContentsGetAllDynamicList
     * @request GET:/api/DosageFormUnitTypeContents/GetAllDynamic
     * @secure
     */
    dosageFormUnitTypeContentsGetAllDynamicList: (
      query: IDosageFormUnitTypeContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDosageFormUnitTypeContentDynamicResponse, any>({
        path: `/api/DosageFormUnitTypeContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DosageFormUnitTypes
     * @name DosageFormUnitTypesGetAllDynamicList
     * @request GET:/api/DosageFormUnitTypes/GetAllDynamic
     * @secure
     */
    dosageFormUnitTypesGetAllDynamicList: (
      query: IDosageFormUnitTypesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDosageFormUnitTypeDynamicResponse, any>({
        path: `/api/DosageFormUnitTypes/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GenderContents
     * @name GenderContentsGetAllDynamicList
     * @request GET:/api/GenderContents/GetAllDynamic
     * @secure
     */
    genderContentsGetAllDynamicList: (
      query: IGenderContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IGenderContentDynamicResponse, any>({
        path: `/api/GenderContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Genders
     * @name GendersGetAllDynamicList
     * @request GET:/api/Genders/GetAllDynamic
     * @secure
     */
    gendersGetAllDynamicList: (
      query: IGendersGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IGenderDynamicResponse, any>({
        path: `/api/Genders/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Labels
     * @name LabelsGetAllDynamicList
     * @request GET:/api/Labels/GetAllDynamic
     * @secure
     */
    labelsGetAllDynamicList: (query: ILabelsGetAllDynamicListParams, params: RequestParams = {}) =>
      this.request<ILabelDynamicResponse, any>({
        path: `/api/Labels/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Labels
     * @name LabelsCreateCreate
     * @request POST:/api/Labels/Create
     * @secure
     */
    labelsCreateCreate: (data: ILabel, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Labels/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Labels
     * @name LabelsPatchPartialUpdate
     * @request PATCH:/api/Labels/Patch/{id}
     * @secure
     */
    labelsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Labels/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Labels
     * @name LabelsDeleteDelete
     * @request DELETE:/api/Labels/Delete/{id}
     * @secure
     */
    labelsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Labels/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesGetAllMajorDynamicList
     * @request GET:/api/Languages/GetAllMajorDynamic
     * @secure
     */
    languagesGetAllMajorDynamicList: (
      query: ILanguagesGetAllMajorDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ILanguageDynamicResponse, any>({
        path: `/api/Languages/GetAllMajorDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesGetAllDynamicList
     * @request GET:/api/Languages/GetAllDynamic
     * @secure
     */
    languagesGetAllDynamicList: (
      query: ILanguagesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ILanguageDynamicResponse, any>({
        path: `/api/Languages/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesGetDefaultLanguageList
     * @request GET:/api/Languages/GetDefaultLanguage
     * @secure
     */
    languagesGetDefaultLanguageList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Languages/GetDefaultLanguage`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MediaUploads
     * @name MediaUploadsUploadFileToCloudCreate
     * @request POST:/api/MediaUploads/UploadFileToCloud
     * @secure
     */
    mediaUploadsUploadFileToCloudCreate: (data: ICloudFileViewModel, params: RequestParams = {}) =>
      this.request<ICloudFileViewModel, any>({
        path: `/api/MediaUploads/UploadFileToCloud`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MediaUploads
     * @name MediaUploadsRemoveFileFromCloudUpdate
     * @request PUT:/api/MediaUploads/RemoveFileFromCloud
     * @secure
     */
    mediaUploadsRemoveFileFromCloudUpdate: (
      query: IMediaUploadsRemoveFileFromCloudUpdateParams,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/MediaUploads/RemoveFileFromCloud`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionContents
     * @name MedicalConditionContentsGetAllDynamicList
     * @request GET:/api/MedicalConditionContents/GetAllDynamic
     * @secure
     */
    medicalConditionContentsGetAllDynamicList: (
      query: IMedicalConditionContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMedicalConditionContentDynamicResponse, any>({
        path: `/api/MedicalConditionContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionIngredientContents
     * @name MedicalConditionIngredientContentsGetAllDynamicList
     * @request GET:/api/MedicalConditionIngredientContents/GetAllDynamic
     * @secure
     */
    medicalConditionIngredientContentsGetAllDynamicList: (
      query: IMedicalConditionIngredientContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMedicalConditionIngredientContentDynamicResponse, any>({
        path: `/api/MedicalConditionIngredientContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditionIngredients
     * @name MedicalConditionIngredientsGetAllDynamicList
     * @request GET:/api/MedicalConditionIngredients/GetAllDynamic
     * @secure
     */
    medicalConditionIngredientsGetAllDynamicList: (
      query: IMedicalConditionIngredientsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMedicalConditionIngredientDynamicResponse, any>({
        path: `/api/MedicalConditionIngredients/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicalConditions
     * @name MedicalConditionsGetAllDynamicList
     * @request GET:/api/MedicalConditions/GetAllDynamic
     * @secure
     */
    medicalConditionsGetAllDynamicList: (
      query: IMedicalConditionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMedicalConditionDynamicResponse, any>({
        path: `/api/MedicalConditions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MedicationContents
     * @name MedicationContentsGetAllDynamicList
     * @request GET:/api/MedicationContents/GetAllDynamic
     * @secure
     */
    medicationContentsGetAllDynamicList: (
      query: IMedicationContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMedicationContentDynamicResponse, any>({
        path: `/api/MedicationContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Medications
     * @name MedicationsGetAllDynamicList
     * @request GET:/api/Medications/GetAllDynamic
     * @secure
     */
    medicationsGetAllDynamicList: (
      query: IMedicationsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMedicationDynamicResponse, any>({
        path: `/api/Medications/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrderStatuses
     * @name OrderStatusesGetAllDynamicList
     * @request GET:/api/OrderStatuses/GetAllDynamic
     * @secure
     */
    orderStatusesGetAllDynamicList: (
      query: IOrderStatusesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IOrderStatusDynamicResponse, any>({
        path: `/api/OrderStatuses/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name PagesGetLandingPageDetail
     * @request GET:/api/Pages/GetLandingPage/{languageID}
     * @secure
     */
    pagesGetLandingPageDetail: (languageId: string, params: RequestParams = {}) =>
      this.request<ILandingPageResponse, any>({
        path: `/api/Pages/GetLandingPage/${languageId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name PagesGetTermsPageDetail
     * @request GET:/api/Pages/GetTermsPage/{languageID}
     * @secure
     */
    pagesGetTermsPageDetail: (languageId: string, params: RequestParams = {}) =>
      this.request<ILegalResponse, any>({
        path: `/api/Pages/GetTermsPage/${languageId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name PagesGetPrivacyPageDetail
     * @request GET:/api/Pages/GetPrivacyPage/{languageID}
     * @secure
     */
    pagesGetPrivacyPageDetail: (languageId: string, params: RequestParams = {}) =>
      this.request<ILegalResponse, any>({
        path: `/api/Pages/GetPrivacyPage/${languageId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanies
     * @name PharmaCompaniesGetAllDynamicList
     * @request GET:/api/PharmaCompanies/GetAllDynamic
     * @secure
     */
    pharmaCompaniesGetAllDynamicList: (
      query: IPharmaCompaniesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IPharmaCompanyDynamicResponse, any>({
        path: `/api/PharmaCompanies/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanies
     * @name PharmaCompaniesGetPharmaCompaniesDynamicList
     * @request GET:/api/PharmaCompanies/GetPharmaCompaniesDynamic
     * @secure
     */
    pharmaCompaniesGetPharmaCompaniesDynamicList: (
      query: IPharmaCompaniesGetPharmaCompaniesDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/PharmaCompanies/GetPharmaCompaniesDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderDetails
     * @name PharmaCompanyOrderDetailsGetAllDynamicList
     * @request GET:/api/PharmaCompanyOrderDetails/GetAllDynamic
     * @secure
     */
    pharmaCompanyOrderDetailsGetAllDynamicList: (
      query: IPharmaCompanyOrderDetailsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IPharmaCompanyOrderDetailDynamicResponse, any>({
        path: `/api/PharmaCompanyOrderDetails/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderDetails
     * @name PharmaCompanyOrderDetailsCreateCreate
     * @request POST:/api/PharmaCompanyOrderDetails/Create
     * @secure
     */
    pharmaCompanyOrderDetailsCreateCreate: (
      data: IPharmaCompanyOrderDetail,
      params: RequestParams = {},
    ) =>
      this.request<IPharmaCompanyOrderDetail, any>({
        path: `/api/PharmaCompanyOrderDetails/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderDetails
     * @name PharmaCompanyOrderDetailsPatchPartialUpdate
     * @request PATCH:/api/PharmaCompanyOrderDetails/Patch/{id}
     * @secure
     */
    pharmaCompanyOrderDetailsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/PharmaCompanyOrderDetails/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderDetails
     * @name PharmaCompanyOrderDetailsDeleteDelete
     * @request DELETE:/api/PharmaCompanyOrderDetails/Delete/{id}
     * @secure
     */
    pharmaCompanyOrderDetailsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IPharmaCompanyOrderDetail, any>({
        path: `/api/PharmaCompanyOrderDetails/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrders
     * @name PharmaCompanyOrdersGetAllDynamicList
     * @request GET:/api/PharmaCompanyOrders/GetAllDynamic
     * @secure
     */
    pharmaCompanyOrdersGetAllDynamicList: (
      query: IPharmaCompanyOrdersGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IPharmaCompanyOrderDynamicResponse, any>({
        path: `/api/PharmaCompanyOrders/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrders
     * @name PharmaCompanyOrdersCreateCreate
     * @request POST:/api/PharmaCompanyOrders/Create
     * @secure
     */
    pharmaCompanyOrdersCreateCreate: (data: IPharmaCompanyOrder, params: RequestParams = {}) =>
      this.request<IPharmaCompanyOrder, any>({
        path: `/api/PharmaCompanyOrders/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrders
     * @name PharmaCompanyOrdersPatchPartialUpdate
     * @request PATCH:/api/PharmaCompanyOrders/Patch/{id}
     * @secure
     */
    pharmaCompanyOrdersPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/PharmaCompanyOrders/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrders
     * @name PharmaCompanyOrdersDeleteDelete
     * @request DELETE:/api/PharmaCompanyOrders/Delete/{id}
     * @secure
     */
    pharmaCompanyOrdersDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IPharmaCompanyOrder, any>({
        path: `/api/PharmaCompanyOrders/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrdersHelper
     * @name PharmaCompanyOrdersHelperSyncOrdersDetail
     * @request GET:/api/PharmaCompanyOrdersHelper/SyncOrders/{tenantID}
     * @secure
     */
    pharmaCompanyOrdersHelperSyncOrdersDetail: (tenantId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/PharmaCompanyOrdersHelper/SyncOrders/${tenantId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderTenants
     * @name PharmaCompanyOrderTenantsGetAllDynamicList
     * @request GET:/api/PharmaCompanyOrderTenants/GetAllDynamic
     * @secure
     */
    pharmaCompanyOrderTenantsGetAllDynamicList: (
      query: IPharmaCompanyOrderTenantsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IPharmaCompanyOrderTenantDynamicResponse, any>({
        path: `/api/PharmaCompanyOrderTenants/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderTenants
     * @name PharmaCompanyOrderTenantsCreateCreate
     * @request POST:/api/PharmaCompanyOrderTenants/Create
     * @secure
     */
    pharmaCompanyOrderTenantsCreateCreate: (
      data: IPharmaCompanyOrderTenant,
      params: RequestParams = {},
    ) =>
      this.request<IPharmaCompanyOrderTenant, any>({
        path: `/api/PharmaCompanyOrderTenants/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderTenants
     * @name PharmaCompanyOrderTenantsCreateBulkCreate
     * @request POST:/api/PharmaCompanyOrderTenants/CreateBulk
     * @secure
     */
    pharmaCompanyOrderTenantsCreateBulkCreate: (
      data: IPharmaCompanyOrderTenantBulk,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/PharmaCompanyOrderTenants/CreateBulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderTenants
     * @name PharmaCompanyOrderTenantsPatchPartialUpdate
     * @request PATCH:/api/PharmaCompanyOrderTenants/Patch/{id}
     * @secure
     */
    pharmaCompanyOrderTenantsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/PharmaCompanyOrderTenants/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PharmaCompanyOrderTenants
     * @name PharmaCompanyOrderTenantsDeleteDelete
     * @request DELETE:/api/PharmaCompanyOrderTenants/Delete/{id}
     * @secure
     */
    pharmaCompanyOrderTenantsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IPharmaCompanyOrderTenant, any>({
        path: `/api/PharmaCompanyOrderTenants/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBrandContents
     * @name ProductBrandContentsGetAllDynamicList
     * @request GET:/api/ProductBrandContents/GetAllDynamic
     * @secure
     */
    productBrandContentsGetAllDynamicList: (
      query: IProductBrandContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductBrandContentDynamicResponse, any>({
        path: `/api/ProductBrandContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductBrands
     * @name ProductBrandsGetAllDynamicList
     * @request GET:/api/ProductBrands/GetAllDynamic
     * @secure
     */
    productBrandsGetAllDynamicList: (
      query: IProductBrandsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductBrandDynamicResponse, any>({
        path: `/api/ProductBrands/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductCases
     * @name ProductCasesGetAllDynamicList
     * @request GET:/api/ProductCases/GetAllDynamic
     * @secure
     */
    productCasesGetAllDynamicList: (
      query: IProductCasesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductCaseDynamicResponse, any>({
        path: `/api/ProductCases/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductContents
     * @name ProductContentsGetAllDynamicList
     * @request GET:/api/ProductContents/GetAllDynamic
     * @secure
     */
    productContentsGetAllDynamicList: (
      query: IProductContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductContentDynamicResponse, any>({
        path: `/api/ProductContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductIngredients
     * @name ProductIngredientsGetAllDynamicList
     * @request GET:/api/ProductIngredients/GetAllDynamic
     * @secure
     */
    productIngredientsGetAllDynamicList: (
      query: IProductIngredientsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductIngredientDynamicResponse, any>({
        path: `/api/ProductIngredients/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductLeaflets
     * @name ProductLeafletsGetAllDynamicList
     * @request GET:/api/ProductLeaflets/GetAllDynamic
     * @secure
     */
    productLeafletsGetAllDynamicList: (
      query: IProductLeafletsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductLeafletDynamicResponse, any>({
        path: `/api/ProductLeaflets/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductMedicalConditions
     * @name ProductMedicalConditionsGetAllDynamicList
     * @request GET:/api/ProductMedicalConditions/GetAllDynamic
     * @secure
     */
    productMedicalConditionsGetAllDynamicList: (
      query: IProductMedicalConditionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductMedicalConditionDynamicResponse, any>({
        path: `/api/ProductMedicalConditions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductMedications
     * @name ProductMedicationsGetAllDynamicList
     * @request GET:/api/ProductMedications/GetAllDynamic
     * @secure
     */
    productMedicationsGetAllDynamicList: (
      query: IProductMedicationsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductMedicationDynamicResponse, any>({
        path: `/api/ProductMedications/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsGetAllDynamicList
     * @request GET:/api/Products/GetAllDynamic
     * @secure
     */
    productsGetAllDynamicList: (
      query: IProductsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductDynamicResponse, any>({
        path: `/api/Products/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductSensitivities
     * @name ProductSensitivitiesGetAllDynamicList
     * @request GET:/api/ProductSensitivities/GetAllDynamic
     * @secure
     */
    productSensitivitiesGetAllDynamicList: (
      query: IProductSensitivitiesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductSensitivityDynamicResponse, any>({
        path: `/api/ProductSensitivities/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitCases
     * @name ProductsTailorFitCasesGetAllDynamicList
     * @request GET:/api/ProductsTailorFitCases/GetAllDynamic
     * @secure
     */
    productsTailorFitCasesGetAllDynamicList: (
      query: IProductsTailorFitCasesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitCaseDynamicResponse, any>({
        path: `/api/ProductsTailorFitCases/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitCases
     * @name ProductsTailorFitCasesCreateCreate
     * @request POST:/api/ProductsTailorFitCases/Create
     * @secure
     */
    productsTailorFitCasesCreateCreate: (
      data: IProductsTailorFitCase,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitCase, any>({
        path: `/api/ProductsTailorFitCases/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitCases
     * @name ProductsTailorFitCasesPatchPartialUpdate
     * @request PATCH:/api/ProductsTailorFitCases/Patch/{id}
     * @secure
     */
    productsTailorFitCasesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductsTailorFitCases/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitCases
     * @name ProductsTailorFitCasesDeleteDelete
     * @request DELETE:/api/ProductsTailorFitCases/Delete/{id}
     * @secure
     */
    productsTailorFitCasesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductsTailorFitCase, any>({
        path: `/api/ProductsTailorFitCases/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitCases
     * @name ProductsTailorFitCasesCreateBulkCreate
     * @request POST:/api/ProductsTailorFitCases/CreateBulk
     * @secure
     */
    productsTailorFitCasesCreateBulkCreate: (data: IRelationshipBulk, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/ProductsTailorFitCases/CreateBulk`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitSessionDosageForms
     * @name ProductsTailorFitSessionDosageFormsGetAllDynamicList
     * @request GET:/api/ProductsTailorFitSessionDosageForms/GetAllDynamic
     * @secure
     */
    productsTailorFitSessionDosageFormsGetAllDynamicList: (
      query: IProductsTailorFitSessionDosageFormsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitSessionDosageFormDynamicResponse, any>({
        path: `/api/ProductsTailorFitSessionDosageForms/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitSessionLogs
     * @name ProductsTailorFitSessionLogsGetAllDynamicList
     * @request GET:/api/ProductsTailorFitSessionLogs/GetAllDynamic
     * @secure
     */
    productsTailorFitSessionLogsGetAllDynamicList: (
      query: IProductsTailorFitSessionLogsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitSessionLogDynamicResponse, any>({
        path: `/api/ProductsTailorFitSessionLogs/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitSessionProducts
     * @name ProductsTailorFitSessionProductsGetAllDynamicList
     * @request GET:/api/ProductsTailorFitSessionProducts/GetAllDynamic
     * @secure
     */
    productsTailorFitSessionProductsGetAllDynamicList: (
      query: IProductsTailorFitSessionProductsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitSessionProductDynamicResponse, any>({
        path: `/api/ProductsTailorFitSessionProducts/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitSessions
     * @name ProductsTailorFitSessionsGetAllDynamicList
     * @request GET:/api/ProductsTailorFitSessions/GetAllDynamic
     * @secure
     */
    productsTailorFitSessionsGetAllDynamicList: (
      query: IProductsTailorFitSessionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitSessionDynamicResponse, any>({
        path: `/api/ProductsTailorFitSessions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitSessionSteps
     * @name ProductsTailorFitSessionStepsGetAllDynamicList
     * @request GET:/api/ProductsTailorFitSessionSteps/GetAllDynamic
     * @secure
     */
    productsTailorFitSessionStepsGetAllDynamicList: (
      query: IProductsTailorFitSessionStepsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitSessionStepDynamicResponse, any>({
        path: `/api/ProductsTailorFitSessionSteps/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitsHelper
     * @name ProductsTailorFitsHelperGeneratePropertyCodeList
     * @request GET:/api/ProductsTailorFitsHelper/GeneratePropertyCode
     * @secure
     */
    productsTailorFitsHelperGeneratePropertyCodeList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/ProductsTailorFitsHelper/GeneratePropertyCode`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitThemes
     * @name ProductsTailorFitThemesGetAllDynamicList
     * @request GET:/api/ProductsTailorFitThemes/GetAllDynamic
     * @secure
     */
    productsTailorFitThemesGetAllDynamicList: (
      query: IProductsTailorFitThemesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitThemeDynamicResponse, any>({
        path: `/api/ProductsTailorFitThemes/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitThemes
     * @name ProductsTailorFitThemesCreateCreate
     * @request POST:/api/ProductsTailorFitThemes/Create
     * @secure
     */
    productsTailorFitThemesCreateCreate: (
      data: IProductsTailorFitTheme,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitTheme, any>({
        path: `/api/ProductsTailorFitThemes/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitThemes
     * @name ProductsTailorFitThemesPatchPartialUpdate
     * @request PATCH:/api/ProductsTailorFitThemes/Patch/{id}
     * @secure
     */
    productsTailorFitThemesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductsTailorFitThemes/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitThemes
     * @name ProductsTailorFitThemesDeleteDelete
     * @request DELETE:/api/ProductsTailorFitThemes/Delete/{id}
     * @secure
     */
    productsTailorFitThemesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductsTailorFitTheme, any>({
        path: `/api/ProductsTailorFitThemes/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitUserProfileContents
     * @name ProductsTailorFitUserProfileContentsGetAllDynamicList
     * @request GET:/api/ProductsTailorFitUserProfileContents/GetAllDynamic
     * @secure
     */
    productsTailorFitUserProfileContentsGetAllDynamicList: (
      query: IProductsTailorFitUserProfileContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitUserProfileContentDynamicResponse, any>({
        path: `/api/ProductsTailorFitUserProfileContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitUserProfileContents
     * @name ProductsTailorFitUserProfileContentsCreateCreate
     * @request POST:/api/ProductsTailorFitUserProfileContents/Create
     * @secure
     */
    productsTailorFitUserProfileContentsCreateCreate: (
      data: IProductsTailorFitUserProfileContent,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitUserProfileContent, any>({
        path: `/api/ProductsTailorFitUserProfileContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitUserProfileContents
     * @name ProductsTailorFitUserProfileContentsPatchPartialUpdate
     * @request PATCH:/api/ProductsTailorFitUserProfileContents/Patch/{id}
     * @secure
     */
    productsTailorFitUserProfileContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductsTailorFitUserProfileContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitUserProfileContents
     * @name ProductsTailorFitUserProfileContentsDeleteDelete
     * @request DELETE:/api/ProductsTailorFitUserProfileContents/Delete/{id}
     * @secure
     */
    productsTailorFitUserProfileContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IProductsTailorFitUserProfileContent, any>({
        path: `/api/ProductsTailorFitUserProfileContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitUserProfiles
     * @name ProductsTailorFitUserProfilesDeleteDelete
     * @request DELETE:/api/ProductsTailorFitUserProfiles/Delete/{appUserID}
     * @secure
     */
    productsTailorFitUserProfilesDeleteDelete: (appUserId: string, params: RequestParams = {}) =>
      this.request<IProductsTailorFitUserProfile, any>({
        path: `/api/ProductsTailorFitUserProfiles/Delete/${appUserId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitUserProfiles
     * @name ProductsTailorFitUserProfilesGetAllDynamicList
     * @request GET:/api/ProductsTailorFitUserProfiles/GetAllDynamic
     * @secure
     */
    productsTailorFitUserProfilesGetAllDynamicList: (
      query: IProductsTailorFitUserProfilesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitUserProfileDynamicResponse, any>({
        path: `/api/ProductsTailorFitUserProfiles/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitUserProfiles
     * @name ProductsTailorFitUserProfilesCreateCreate
     * @request POST:/api/ProductsTailorFitUserProfiles/Create
     * @secure
     */
    productsTailorFitUserProfilesCreateCreate: (
      data: IProductsTailorFitUserProfile,
      params: RequestParams = {},
    ) =>
      this.request<IProductsTailorFitUserProfile, any>({
        path: `/api/ProductsTailorFitUserProfiles/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProductsTailorFitUserProfiles
     * @name ProductsTailorFitUserProfilesPatchPartialUpdate
     * @request PATCH:/api/ProductsTailorFitUserProfiles/Patch/{appUserID}
     * @secure
     */
    productsTailorFitUserProfilesPatchPartialUpdate: (
      appUserId: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/ProductsTailorFitUserProfiles/Patch/${appUserId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagContents
     * @name RedFlagContentsGetAllDynamicList
     * @request GET:/api/RedFlagContents/GetAllDynamic
     * @secure
     */
    redFlagContentsGetAllDynamicList: (
      query: IRedFlagContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedFlagContentDynamicResponse, any>({
        path: `/api/RedFlagContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagOptionContents
     * @name RedFlagOptionContentsGetAllDynamicList
     * @request GET:/api/RedFlagOptionContents/GetAllDynamic
     * @secure
     */
    redFlagOptionContentsGetAllDynamicList: (
      query: IRedFlagOptionContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedFlagOptionContentDynamicResponse, any>({
        path: `/api/RedFlagOptionContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagOptions
     * @name RedFlagOptionsGetAllDynamicList
     * @request GET:/api/RedFlagOptions/GetAllDynamic
     * @secure
     */
    redFlagOptionsGetAllDynamicList: (
      query: IRedFlagOptionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedFlagOptionDynamicResponse, any>({
        path: `/api/RedFlagOptions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagPageContents
     * @name RedFlagPageContentsGetAllDynamicList
     * @request GET:/api/RedFlagPageContents/GetAllDynamic
     * @secure
     */
    redFlagPageContentsGetAllDynamicList: (
      query: IRedFlagPageContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedFlagPageContentDynamicResponse, any>({
        path: `/api/RedFlagPageContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagPages
     * @name RedFlagPagesGetAllDynamicList
     * @request GET:/api/RedFlagPages/GetAllDynamic
     * @secure
     */
    redFlagPagesGetAllDynamicList: (
      query: IRedFlagPagesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedFlagPageDynamicResponse, any>({
        path: `/api/RedFlagPages/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlags
     * @name RedFlagsGetAllDynamicList
     * @request GET:/api/RedFlags/GetAllDynamic
     * @secure
     */
    redFlagsGetAllDynamicList: (
      query: IRedFlagsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedFlagDynamicResponse, any>({
        path: `/api/RedFlags/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedFlagTypes
     * @name RedFlagTypesGetAllDynamicList
     * @request GET:/api/RedFlagTypes/GetAllDynamic
     * @secure
     */
    redFlagTypesGetAllDynamicList: (
      query: IRedFlagTypesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedFlagTypeDynamicResponse, any>({
        path: `/api/RedFlagTypes/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedisParams
     * @name RedisParamsGetAllDynamicList
     * @request GET:/api/RedisParams/GetAllDynamic
     * @secure
     */
    redisParamsGetAllDynamicList: (
      query: IRedisParamsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IRedisParamDynamicResponse, any>({
        path: `/api/RedisParams/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedisParams
     * @name RedisParamsCreateCreate
     * @request POST:/api/RedisParams/Create
     * @secure
     */
    redisParamsCreateCreate: (data: IRedisParam, params: RequestParams = {}) =>
      this.request<IRedisParam, any>({
        path: `/api/RedisParams/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedisParams
     * @name RedisParamsPatchPartialUpdate
     * @request PATCH:/api/RedisParams/Patch/{id}
     * @secure
     */
    redisParamsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/RedisParams/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags RedisParams
     * @name RedisParamsDeleteDelete
     * @request DELETE:/api/RedisParams/Delete/{id}
     * @secure
     */
    redisParamsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IRedisParam, any>({
        path: `/api/RedisParams/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sensitivities
     * @name SensitivitiesGetAllDynamicList
     * @request GET:/api/Sensitivities/GetAllDynamic
     * @secure
     */
    sensitivitiesGetAllDynamicList: (
      query: ISensitivitiesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ISensitivityDynamicResponse, any>({
        path: `/api/Sensitivities/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SensitivityContents
     * @name SensitivityContentsGetAllDynamicList
     * @request GET:/api/SensitivityContents/GetAllDynamic
     * @secure
     */
    sensitivityContentsGetAllDynamicList: (
      query: ISensitivityContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ISensitivityContentDynamicResponse, any>({
        path: `/api/SensitivityContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sessions
     * @name SessionsGetSessionsDynamicList
     * @request GET:/api/Sessions/GetSessionsDynamic
     * @secure
     */
    sessionsGetSessionsDynamicList: (
      query: ISessionsGetSessionsDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Sessions/GetSessionsDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sessions
     * @name SessionsGetSessionLogsDynamicList
     * @request GET:/api/Sessions/GetSessionLogsDynamic
     * @secure
     */
    sessionsGetSessionLogsDynamicList: (
      query: ISessionsGetSessionLogsDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Sessions/GetSessionLogsDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sessions
     * @name SessionsGetSessionDosageFormsDynamicList
     * @request GET:/api/Sessions/GetSessionDosageFormsDynamic
     * @secure
     */
    sessionsGetSessionDosageFormsDynamicList: (
      query: ISessionsGetSessionDosageFormsDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Sessions/GetSessionDosageFormsDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sessions
     * @name SessionsGetSessionProductsDynamicList
     * @request GET:/api/Sessions/GetSessionProductsDynamic
     * @secure
     */
    sessionsGetSessionProductsDynamicList: (
      query: ISessionsGetSessionProductsDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBotSessionProduct[], any>({
        path: `/api/Sessions/GetSessionProductsDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sessions
     * @name SessionsGetSessionMedicalConditionsDynamicList
     * @request GET:/api/Sessions/GetSessionMedicalConditionsDynamic
     * @secure
     */
    sessionsGetSessionMedicalConditionsDynamicList: (
      query: ISessionsGetSessionMedicalConditionsDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Sessions/GetSessionMedicalConditionsDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sessions
     * @name SessionsGetSessionSensitivitiesDynamicList
     * @request GET:/api/Sessions/GetSessionSensitivitiesDynamic
     * @secure
     */
    sessionsGetSessionSensitivitiesDynamicList: (
      query: ISessionsGetSessionSensitivitiesDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Sessions/GetSessionSensitivitiesDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sessions
     * @name SessionsGetMonthlyReportTotalsDetail
     * @request GET:/api/Sessions/GetMonthlyReportTotals/{tenantID}/{year}/{month}
     * @secure
     */
    sessionsGetMonthlyReportTotalsDetail: (
      tenantId: string,
      year: number,
      month: number,
      params: RequestParams = {},
    ) =>
      this.request<IReportResponse, any>({
        path: `/api/Sessions/GetMonthlyReportTotals/${tenantId}/${year}/${month}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name SettingsGetTenantDetail
     * @request GET:/api/Settings/GetTenant/{tenantID}
     * @secure
     */
    settingsGetTenantDetail: (tenantId: string, params: RequestParams = {}) =>
      this.request<ITenantResponse, any>({
        path: `/api/Settings/GetTenant/${tenantId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name SettingsUpdateTenantUpdate
     * @request PUT:/api/Settings/UpdateTenant
     * @secure
     */
    settingsUpdateTenantUpdate: (data: ITenantRequest, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Settings/UpdateTenant`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name SettingsUpdateTenantBotContentUpdate
     * @request PUT:/api/Settings/UpdateTenantBotContent
     * @secure
     */
    settingsUpdateTenantBotContentUpdate: (
      data: ITenantBotContentItem,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/Settings/UpdateTenantBotContent`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name SettingsCreateTenantBotContentCreate
     * @request POST:/api/Settings/CreateTenantBotContent
     * @secure
     */
    settingsCreateTenantBotContentCreate: (
      data: ITenantBotContentItemBase,
      params: RequestParams = {},
    ) =>
      this.request<ITenantBotContentItem, any>({
        path: `/api/Settings/CreateTenantBotContent`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantBotContents
     * @name TenantBotContentsGetAllDynamicList
     * @request GET:/api/TenantBotContents/GetAllDynamic
     * @secure
     */
    tenantBotContentsGetAllDynamicList: (
      query: ITenantBotContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ITenantBotContentDynamicResponse, any>({
        path: `/api/TenantBotContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantIgnoreProducts
     * @name TenantIgnoreProductsGetAllDynamicList
     * @request GET:/api/TenantIgnoreProducts/GetAllDynamic
     * @secure
     */
    tenantIgnoreProductsGetAllDynamicList: (
      query: ITenantIgnoreProductsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ITenantIgnoreProductDynamicResponse, any>({
        path: `/api/TenantIgnoreProducts/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantIgnoreProducts
     * @name TenantIgnoreProductsCreateCreate
     * @request POST:/api/TenantIgnoreProducts/Create
     * @secure
     */
    tenantIgnoreProductsCreateCreate: (data: ITenantIgnoreProduct, params: RequestParams = {}) =>
      this.request<ITenantIgnoreProduct, any>({
        path: `/api/TenantIgnoreProducts/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantIgnoreProducts
     * @name TenantIgnoreProductsPatchPartialUpdate
     * @request PATCH:/api/TenantIgnoreProducts/Patch/{id}
     * @secure
     */
    tenantIgnoreProductsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/TenantIgnoreProducts/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantIgnoreProducts
     * @name TenantIgnoreProductsDeleteDelete
     * @request DELETE:/api/TenantIgnoreProducts/Delete/{id}
     * @secure
     */
    tenantIgnoreProductsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ITenantIgnoreProduct, any>({
        path: `/api/TenantIgnoreProducts/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantProductContents
     * @name TenantProductContentsGetAllDynamicList
     * @request GET:/api/TenantProductContents/GetAllDynamic
     * @secure
     */
    tenantProductContentsGetAllDynamicList: (
      query: ITenantProductContentsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ITenantProductContentDynamicResponse, any>({
        path: `/api/TenantProductContents/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantProductContents
     * @name TenantProductContentsCreateCreate
     * @request POST:/api/TenantProductContents/Create
     * @secure
     */
    tenantProductContentsCreateCreate: (data: ITenantProductContent, params: RequestParams = {}) =>
      this.request<ITenantProductContent, any>({
        path: `/api/TenantProductContents/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantProductContents
     * @name TenantProductContentsPatchPartialUpdate
     * @request PATCH:/api/TenantProductContents/Patch/{id}
     * @secure
     */
    tenantProductContentsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/TenantProductContents/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantProductContents
     * @name TenantProductContentsDeleteDelete
     * @request DELETE:/api/TenantProductContents/Delete/{id}
     * @secure
     */
    tenantProductContentsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ITenantProductContent, any>({
        path: `/api/TenantProductContents/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantProducts
     * @name TenantProductsGetAllDynamicList
     * @request GET:/api/TenantProducts/GetAllDynamic
     * @secure
     */
    tenantProductsGetAllDynamicList: (
      query: ITenantProductsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ITenantProductDynamicResponse, any>({
        path: `/api/TenantProducts/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantProducts
     * @name TenantProductsCreateCreate
     * @request POST:/api/TenantProducts/Create
     * @secure
     */
    tenantProductsCreateCreate: (data: ITenantProduct, params: RequestParams = {}) =>
      this.request<ITenantProduct, any>({
        path: `/api/TenantProducts/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantProducts
     * @name TenantProductsPatchPartialUpdate
     * @request PATCH:/api/TenantProducts/Patch/{id}
     * @secure
     */
    tenantProductsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/TenantProducts/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantProducts
     * @name TenantProductsDeleteDelete
     * @request DELETE:/api/TenantProducts/Delete/{id}
     * @secure
     */
    tenantProductsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ITenantProduct, any>({
        path: `/api/TenantProducts/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantProductsHelper
     * @name TenantProductsHelperGetTotalNewProductsDetail
     * @request GET:/api/TenantProductsHelper/GetTotalNewProducts/{tenantID}
     * @secure
     */
    tenantProductsHelperGetTotalNewProductsDetail: (tenantId: string, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/TenantProductsHelper/GetTotalNewProducts/${tenantId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantProductsHelper
     * @name TenantProductsHelperGetTotalPendingProductsDetail
     * @request GET:/api/TenantProductsHelper/GetTotalPendingProducts/{tenantID}
     * @secure
     */
    tenantProductsHelperGetTotalPendingProductsDetail: (
      tenantId: string,
      params: RequestParams = {},
    ) =>
      this.request<number, any>({
        path: `/api/TenantProductsHelper/GetTotalPendingProducts/${tenantId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantProductsHelper
     * @name TenantProductsHelperSyncCreate
     * @request POST:/api/TenantProductsHelper/Sync
     * @secure
     */
    tenantProductsHelperSyncCreate: (data: ISyncProductsRequest, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/TenantProductsHelper/Sync`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tenants
     * @name TenantsGetAllDynamicList
     * @request GET:/api/Tenants/GetAllDynamic
     * @secure
     */
    tenantsGetAllDynamicList: (
      query: ITenantsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ITenantDynamicResponse, any>({
        path: `/api/Tenants/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tenants
     * @name TenantsGetTenantsDynamicList
     * @request GET:/api/Tenants/GetTenantsDynamic
     * @secure
     */
    tenantsGetTenantsDynamicList: (
      query: ITenantsGetTenantsDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/Tenants/GetTenantsDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
}
