import { AxiosResponse } from 'axios';
import { DynamicResult, patchFiles, postFiles } from 'modules/dynamic-service';
import {
  contains,
  dateRange,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import { makeUniqueFileName } from 'utils/file-uploader';
import {
  API,
  apiRtk,
  makePatchArgs,
  rtkAdapterDynamicCount,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IBotUserProfile } from '__generated__/api';
import {
  BotUserProfileInputPatch,
  BotUserProfileInputPost,
  BotUserProfileSource,
  GetBotAnalyticsInfoResponse,
  GetBotAnalyticsInput,
  GetBotAnalyticsProductsResponseItem,
  GetBotResult,
  IGridBotUserProfile,
  IGridBotUserProfileArgs,
} from './models';

const REVALIDATE_KEY = 'Bots' as const;

const dynamic = dynamicNamespace<IBotUserProfile>();

export * from './models';

class Service {
  async getNumberOfBots(args: { tenantID: string }) {
    const { tenantID } = args;
    const response = await API.api.botUserProfilesGetAllDynamicList({
      Filter: mergeFilters(
        dynamic.makeFilter('tenantID', tenantID, equals),
        dynamic.makeFilter('isActive', true, decoratorIsNotNullable(equals)),
      ).join('&&'),
      Select: dynamic.select('id'),
      Count: true,
    });

    return rtkAdapterDynamicCount<number>(response as unknown as any);
  }
}

export const ServiceBotUserProfiles = new Service();

type PatchInput = PatchPartial<BotUserProfileInputPatch, 'appIdentityUserID'>;

export const apiBotUserProfiles = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceBotsByTenantID: build.query({
      queryFn: async (tenantID: string) => {
        const response = await API.api.botUserProfilesGetAllDynamicList({
          Filter: mergeFilters(dynamic.makeFilter('tenantID', tenantID, equals)).join('&&'),
          Select: dynamic.select('appIdentityUserID as id', 'title', 'icon', 'isActive'),
          OrderBy: dynamic.orderBy('title', 'asc'),
        });

        return rtkAdapterDynamicToSource<BotUserProfileSource>(response as unknown as any);
      },
    }),
    getGridBots: build.query({
      queryFn: async (arg: IGridBotUserProfileArgs) => {
        const response = await API.api.botUserProfilesGetAllDynamicList({
          Filter: mergeFilters(
            dynamic.makeFilter('tenantID', arg.tenantID, equals),
            dynamic.makeFilter('isActive', arg.isActive, decoratorIsNotNullable(equals)),
            dynamic.makeFilter(['title', 'propertyCode'], arg.search, contains),
          ).join('&&'),
          Select: dynamic.select(
            'appIdentityUserID',
            'title',
            'icon',
            'fromAge',
            'toAge',
            'propertyCode',
            'isActive',
            'botTheme.title as botTheme',
            'language.culture as language',
            'botSessions.Count() as numberOfSessions',
            'botCases.Where(c => c.isActive).Count() as numberOfScenarios',
          ),
          Take: arg.take,
          Skip: arg.skip,
          Count: true,
          OrderBy: arg.orderBy.field ? dynamic.orderBy(arg.orderBy) : 'title asc, isActive desc',
        });

        return response as AxiosResponse<DynamicResult<IGridBotUserProfile>>;
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getBot: build.query({
      queryFn: async (appIdentityUserID: string) => {
        const response = await API.api.botUserProfilesGetAllDynamicList({
          Filter: mergeFilters(
            dynamic.makeFilter('appIdentityUserID', appIdentityUserID, equals),
          ).join('&&'),
          Select: dynamic.select(
            'appIdentityUserID',
            'iconPosition',
            'iconPositionHorizontalMargin',
            'iconPositionVerticalMargin',
            'languageID',
            'icon',
            'botZIndex',

            'fromAge',
            'toAge',

            'botThemeID',
            'useForVitAssist',
            'useForPharmAssist',

            'displayGenderOther',
            'displaySearch',
            'displayRedFlagConfirm',
            'displayLanguageSelector',
            'displayRecommendationBasket',
            'displayUnitPrice',
            'displayProductsInfoHeader',
            'displayProductMoreInfo',
            'displayFilterByActiveIngredients',
            'displayFilterByDosageForms',
            'displayAge',
            'displaySalesValidDate',

            'chooseScenarioLabel',
            'chooseGenderTitleLabel',
            'chooseGenderSubTitleLabel',

            'filterMedicalConditionsTitleLabel',
            'filterMedicalConditionsSubTitleLabel',
            'FilterDosageFormsTitleLabel',
            'filterDosageFormsSubTitleLabel',
            'filterDietaryPreferencesTitleLabel',
            'filterDietaryPreferencesSubTitleLabel',

            'title',
            'brandName',
            'propertyCode',
            'tenantID',
            'currency',
            'addToCartCode',
            'applyAddToCart',
            'trackLog',
            'internal',
            'universal',
            'displayExternalVendor',
            'displayProductZooming',

            'isActive',

            'cssInjection',

            'botSessions.Count() > 1 as isHasSessions',
          ),
          Take: 1,
        });
        return rtkAdapterDynamicItem<GetBotResult>(response as unknown as any);
      },
      providesTags: (_, __, appIdentityUserID) => [{ type: REVALIDATE_KEY, id: appIdentityUserID }],
    }),
    getBotAnalyticsInfo: build.query({
      queryFn: async (arg: GetBotAnalyticsInput) => {
        const response = await API.api.botUserProfilesGetAllDynamicList({
          Filter: mergeFilters(dynamic.makeFilter('appIdentityUserID', arg.botID, equals)).join(
            '&&',
          ),
          Select: dynamic.select(
            'botCases.Where(c => c.isActive).Count() as activeBotCasesCount',

            `botSessions.Where(s => ${dynamic.makeFilter(
              's.createdDate',
              arg.dateRange,
              dateRange,
            )}).Select(s => new { s.age as age, s.createdDate as date, s.botSessionStep.isSessionComplete as isCompleted, s.gender.catalogName as gender }) as botSessions`,
          ),
        });

        return rtkAdapterDynamicItem<GetBotAnalyticsInfoResponse>(response as unknown as any);
      },
    }),
    getBotAnalyticsInfoProducts: build.query({
      queryFn: async (arg: GetBotAnalyticsInput) => {
        const response = await API.api.botSessionTenantProductActionsGetAllDynamicList({
          Filter: mergeFilters(
            dynamic.makeFilter('botSession.botUserProfileID', arg.botID, equals),
            dynamic.makeFilter('entryDate', arg.dateRange, dateRange),
          ).join('&&'),
          Select: dynamic.select('actionType', 'entryDate'),
        });

        return rtkAdapterDynamicToSource<GetBotAnalyticsProductsResponseItem>(
          response as unknown as any,
        );
      },
    }),
    patchBot: build.mutation({
      queryFn: async ({ dataNew, dataOld }: { dataNew: PatchInput; dataOld?: PatchInput }) => {
        const [patchData] = await patchFiles(dataNew, dataOld, {
          icon: makeUniqueFileName('icon'),
        });

        return API.api.botUserProfilesPatchPartialUpdate(
          ...makePatchArgs(patchData, 'appIdentityUserID'),
        );
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    deleteBot: build.mutation({
      queryFn: (data) => API.api.botUserProfilesDeleteDelete(String(data?.id)),
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    postBot: build.mutation({
      queryFn: async (input: BotUserProfileInputPost) => {
        const [postData, transaction] = await postFiles(input, {
          icon: makeUniqueFileName('icon'),
        });

        try {
          return API.api.botUserProfilesCreateCreate(postData as unknown as any);
        } catch (e) {
          transaction();
          throw e;
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    updateBotPropertyCode: build.query({
      queryFn: async (arg: void) => {
        return API.api.botsHelperGeneratePropertyCodeList();
      },
    }),
  }),
});
